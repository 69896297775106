export default {
    locale: 'en',
    isoLocale: 'en-US',
    menu: {
        historias: 'Historias felices',
        metodologia: 'Nuestra metodologia',
        luna: 'Luna de miel',
        eventos: 'Eventos',
        paquetes: 'Paquetes',
        ayuda: 'Centro de ayuda',
        contacto: 'Contacto',
        login: 'Login',
        galeria: 'Galeria',
        mi_panel: 'Mi panel',
        evento: {
            evento: 'Evento',
            galeria: 'Galeria',
            ceremonia: 'Ceremonia',
            cronograma: 'Cronograma'
        },
    },
    index: {
        hero: {
            title: 'Eventos perfectos <br> sin preocupaciones',
            p: 'Recibe un presupuesto en menos de 24 horas ',
            btn: 'Comienza ahora',
        },
        section_historias: {
            title: 'Historias de parejas felices',
            p: 'Elije el mejor comienzo con TraveleSoft',
        },
        section_training: {
            title: '¿Necesitas ayuda?',
            content: 'Encuentra la respuesta a tu problema, en nuestros videos tutoriales. Tan fácil como dar <b>click</b>.',
            link: 'Ver videos',
            link_training: 'Ver tutorial',
        },
        section_metodologia: {
            title: 'Conoce nuestra metodología',
            p: '"La tranquilidad viene de saber que es lo que sigue."</span> <br>Para cada tipo de evento, tenemos un plan de coordinación',
            btn: 'Da el primer paso',
        },
        section_beneficios: {
            title: '¿Por qué hacemos esto?',
            p: 'Nos apasiona crear momentos únicos y <br>fomentar la unión familiar en cada evento, <br>que con dedicación planificamos para ti. <br>Ofrecemos paquetes personalizados, con todo <br>lo que necesitas, para un evento de ensueño.',
            cordinadores: 'Coordinadores<br> certificados',
            software: 'Software <br> de gestión de eventos',
            garantia: 'Garantia <br> de satisfacción',
        },
        modal_plan: {
            title: 'Descarga el plan de bodas completo',
            p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            check_noticias: 'Quiero recibir noticias de TraveleSoft',
            politicas_privacidad: 'Politicas de privacidad',
        }
    },
    validation: {
        min_length: 'Minimo %{min} caracteres'
    },
    auth: {
        title_reset_password: 'Resetear Contraseña',
        password: 'Contraseña',
        password_placeholder: 'Ingrese Contraseña',
        password_confirm: 'Confirmar Contraseña',
        password_confirm_placeholder: 'Confirmar Contraseña',
        validation_form: 'Las contraseñas deben coincidir',
        reset_password_info: 'La solicitud de actualización es válida por 6 horas y solo puede usarse una vez.',
        reset_password_go_login: 'Ir al login',
        reset_password_message: {
            IRK: 'Solicitud de actualización inválida',
            RKNF: 'Solicitud de actualización no existe en el sistema',
            PUU: 'Password actualizado',
        },
        ok: 'Confirmar'
    },
    felices: {
        hero: {
            title: 'Y vivimos felices <br> por siempre',
            p: 'Conoce las historias de las parejas que han tenido la boda de sus sueños con nosotros.',
        },
        section: {
            btn: 'Conocelo'
        }
    },
    luna: {
        hero: {
            title: 'Destinos de ensueño',
            p: 'Escápate a uno de los destinos más románticos y <br> vive una inolvidable luna de miel ',
        },
        section: {
            title: 'Recomendados',
            btn: 'Solicita cotización personalizada',
        }
    },
    paquete: {
        hero: {
            title: 'Eventos perfectos,<br> sin complicaciones',
            p: 'La mejor forma de planificar tu gran día.',
            btn: 'Comienza ahora',
        },
        section: {
            title: 'Tenemos un paquete de servicios ideal para ti',
            free: {
                title: 'Honey',
                price: 'Free',
                beneficio1: 'Página para tu evento por 12 meses',
                beneficio2: 'Hasta 150 invitados',
                beneficio3: 'Save the Date por correo',
                beneficio4: 'Reporte confirmaciones de asistencia',
                beneficio5: 'Detalles de ceremonia y locación',
                beneficio6: 'Cronograma del evento',
                beneficio7: 'Wedding countdown',
                btn: 'Probar FREE',
            },
            romance: {
                title: 'Romance',
                beneficio1: 'Paquete Honey +',
                beneficio2: 'Página para tu evento por 18 meses',
                beneficio3: 'Invitados ilimitados',
                beneficio4: 'Save the Date vía mensaje SMS',
                beneficio5: 'Mailing de recordatorio 30 días',
                beneficio6: 'Link a tu Mesa de Regalos',
                beneficio7: 'Asesoría de una planner certificada',
                beneficio8: 'Soporte a invitados vía correo',
                beneficio9: 'Cotizaciones de transporte',
                beneficio10: '10% descuento en Luna de Miel',
                btn: 'Comienza ahora',
            },
            love: {
                title: 'love',
                beneficio1: 'Paquete Romance +',
                beneficio2: 'Hasta 5 mailings personalizados',
                beneficio3: 'Hotel sede del evento',
                beneficio4: 'Motor de reservaciones',
                beneficio5: 'Motor de pagos',
                beneficio6: 'Llamadas de confirmación',
                beneficio7: 'Reportes de Call Center',
                beneficio8: 'Pack de 1000 mensajes SMS',
                beneficio9: 'Mesa Que Viajen los Novios',
                beneficio10: 'Cotización de proveedores',
                beneficio11: 'Soporte telefónico a invitados',
                beneficio12: 'Consola de adminstración',
                btn: 'Comienza ahora'
            }
        },
    },
    training: {
        hero: {
            title: 'Centro de ayuda.',
            p: '¿Tienes dudas sobre alguna función de la página? <br> Encuentra la solución, en nuestros videos paso a paso. ',
        },
        section: {
            title: 'Categorias',
        },
        // ****
        menu: {
            reserva: 'Reservar',
            modificar: 'Modificar',
            pagos: 'Cuentas y pagos',
            transporte: 'Transporte',
            invitados: 'Invitados',
            asistentes: 'Asistentes',
            cronograma: 'Cronograma',
            mesa: 'Mesa de regalos',
        }
        // ****
    },
    contacto: {
        title: 'Cuéntanos los detalles de tú próximo evento.',
        title_componente: 'Llamada call center',
        completa: 'Completa los campos',
        p: 'Recibe un presupuesto <br>en menos de 24 horas.',
        llamanos: 'Llámanos',
        whatsapp: 'Whatsapp',
        messenger: 'Messenger',
        visitanos: 'Visítanos',
        form_title: 'Completa los campos',
        btn: 'Contactarnos',
    },
    login: {
        title: 'Bienvenido a tu consola de administración',
        p: 'Ingresa tu correo y tu clave de confirmación o password',
        btn: 'Entrar',
        olvidaste: '¿Olvidaste tu contraseña?',
        //****
        title_forgot: 'Recuperar Contraseña',
        password_recovery_instructions: 'Recibirá instructiones para restablecer su contraseña',
        // ****
    },
    faqs: {
        title: '¿Tienes dudas?',
        p: 'Encuentra aquí las respuestas a las preguntas frecuentes. <br> No dudes en contactarnos para asistirte de forma personalizada.',
        categorias: 'Categorias',
        questionnaires: {
            'cat1': {
                title: 'Categoria 1',
                description: 'large lorem ipsum dolor sit ammet',
                questions: [
                    {
                        title: '¿CUÁLES SON LOS SERVICIOS QUE OFRECE LA AGENCIA?',
                        content: '<p>NUESTRA AGENCIA TIENE MAS DE 10 AÑOS DE EXPERIENCIA, EN LA PLANIFICACION, COORDINACION Y OPERACION DE EVENTOS EN DESTINO</p>\n' +
                            '<p>NOS APASIONA AYUDAR A LAS FAMILIAS, A ORGANIZAR ESTE DÍA TAN IMPORTANTE EN SUS VIDAS.</p>\n' +
                            '<p>NUESTRO SERVICIO INCLUYE LA COORDINACIÓN DE LAS SIGUIENTES ACTIVIDADES:</p>\n' +
                            '<ul class="list-unstyled">\n' +
                            '  <li>• SELECCIÓN DE HOTEL Y CONTRATO DE BLOQUEO DE HABITACIONES</li>\n' +
                            '  <li>• CREACIÓN DE PÁGINA WEB PERSONALIZADA CON LOS DETALLES DEL EVENTO</li>\n' +
                            '  <li>• PROCESO DE INVITACIÓN SAVE THE DATE, A TRAVÉS DE MAILING MASIVO Y MENSAJES DE TEXTO SMS.</li>\n' +
                            '  <li>• LLAMADAS DEL CALL CENTER A INVITADOS, PARA ACLARACIÓN DE DUDAS Y CONFIRMACIÓN DE ASISTENCIA.</li>\n' +
                            '  <li>• APOYO EN PROCESO DE RESERVACIÓN DE HABITACIONES.</li>\n' +
                            '  <li>• COBRANZA Y PAGO DE LAS RESERVACIONES, EN CÓMODOS ABONOS.</li>\n' +
                            '  <li>• COTIZACIÓN Y APOYO CON CONTRATACIÓN DE TRANSPORTE AÉREO Y TERRESTRE.</li>\n' +
                            '  <li>• COORDINACIÓN DE LA VISITA DE INSPECCIÓN.</li>\n' +
                            '  <li>• COTIZACIÓN Y SELECCIÓN DE PROVEEDORES EXTERNOS.</li>\n' +
                            '  <li>• CONSOLIDACIÓN DEL ROOM LIST, CAMBIOS DE NOMBRE, FECHAS Y CATEGORÍAS.</li>\n' +
                            '</ul>',
                    },
                    {
                        title: '¿EN QUÉ TIPO DE EVENTOS ESTÁN ESPECIALIZADOS?',
                        content: '<p> TRABAJAMOS CON HOTELES TODO INLCUIDO DE LAS MARCAS MAS RECONOCIDAS, EN LAS PRINCIPALES PLAYAS DE NUESTRO PAIS, COMO RIVIERA MAYA, TULUM, COZUMEL, PUERTO VALLARTA Y LOS CABOS.</p>\n' +
                            '<p> OPERAMOS TODO TIPO DE EVENTOS SOCIALES COMO BODAS, RENOVACIONES DE VOTOS, BAUTIZOS, QUINCE AÑOS Y CUALQUIER OCASIÓN QUE UNA FAMILIA QUIERA CELEBRAR.</p>\n' +
                            '<p> TAMBIÉN OFRECEMOS PAQUETES PARA CONGRESOS Y CONVENCIONES, SEMINARIOS, VIAJES DE INCENTIVOS Y OTROS EVENTOS CORPORATIVOS.</p>',
                    },
                    {
                        title: '¿CON QUÉ MARCAS TRABAJAN?',
                        content: '<p>SOMOS LÍDERES EN VENTAS EN HOTELES COMO HARD ROCK, BARCELÓ, GENERATIONS, PALACE RESORTS, GRAND PALLADIUM, IBEROSTAR, OCEAN, DREAMS Y BLUE BAY. TENEMOS UNA OPCIÓN PARA CADA TIPO DE EVENTO Y PRESUPUESTO.</p>',
                    },
                    {
                        title: '¿CUÁL ES EL HOTEL QUE USTEDES RECOMIENDAN?',
                        content: '<p> ¡NOS ENCANTAN TODOS LOS HOTELES CON LOS QUE TRABAJAMOS! TODOS LOGRAN UNA EXCELENTE RELACIÓN PRECIO/CALIDAD, COSA QUE PARA LOS NOVIOS Y SUS INVITADOS, ES LO MÁS IMPORTANTE.</p>\n' +
                            '                      <p> SIN EMBARGO, CREEMOS QUE ENTRE TODAS LAS OPCIONES, HAY UN HOTEL PERFECTO PARA USTEDES Y SUS FAMILIARES.</p>\n' +
                            '                      <p> PRIMERO TOMAMOS EN CUENTA EL TIPO DE CEREMONIA QUE DESEAN TENER, LA DIMENSIÓN APROXIMADA DE ASISTENTES Y LAS FECHAS EN LAS QUE QUIEREN LLEVAR A CABO SU EVENTO.</p>\n' +
                            '                      <p> DESPUÉS INTENTAMOS ENTENDER SUS SUEÑOS, DESEOS Y EXPECTATIVAS, PARA RECOMENDAR OPCIONES DE HOTELES QUE TENGAN EL AMBIENTE, INSTALACIONES Y DETALLES QUE SE ALINEEN CON SU PERFIL.</p>\n' +
                            '                      <p> TOMAMOS EN CUENTA SU PRESUPUESTO Y EL NIVEL SOCIOECONÓMICO DE SUS INVITADOS. QUEREMOS QUE LA SELECCIÓN DEL HOTEL SEA LIGERAMENTE ASPIRACIONAL, ES DECIR, QUE SEA DE IGUAL O MEJOR NIVEL QUE A LO QUE USTEDES Y SUS INVITADOS ESTÁN ACOSTUMBRADOS.</p>\n' +
                            '                      <p> POR ÚLTIMO, ANALIZAMOS LAS PROMOCIONES Y DESCUENTOS QUE HAY DISPONIBLES, AL MOMENTO QUE NOS ESTÁN COTIZANDO. QUEREMOS QUE USTEDES Y SUS INVITADOS OBTENGAN LA MAYOR CANTIDAD DE BENEFICIOS AL MEJOR PRECIO.</p>',
                    },
                    {
                        title: 'NO ME HAN OFRECIDO UN HOTEL TODO INCLUIDO QUE ME INTERESA. ¿PUEDEN TRABAJAR CON ELLOS?',
                        content: '<p> CLARO QUE SI, CON MUCHO GUSTO NOS PONDREMOS DE ACUERDO CON EL HOTEL DE TU ELECCIÓN, PARA QUE NOS IGUALEN LAS CONDICIONES QUE TE HAN OFRECIDO Y NOS COMISIONEN POR NUESTROS SERVICIOS.</p>',
                    },
                    {
                        title: '¿CUÁL ES EL COSTO DE SUS SERVICIOS?',
                        content: '<p> NUESTRA MISIÓN ES LOGRAR LA MAYOR OCUPACIÓN POSIBLE, YA QUE PRÁCTICAMENTE TODOS NUESTROS HONORARIOS LOS CUBRE EL HOTEL, A MODO DE UNA COMISIÓN.</p>\n' +
                            '                      <p> ESTO GENERA UN MODELO GANAR/GANAR/GANAR, PUES TODOS QUEREMOS QUE ASISTAN LA MAYOR CANTIDAD DE INVITADOS POSIBLE. NOS ESFORZAMOS MUCHÍSIMO EN LA CALIDAD DEL SERVICIO Y EN EL SEGUIMIENTO A SUS INVITADOS.</p>',
                    },
                    {
                        title: '¿POR QUÉ CREEN QUE DEBO REALIZAR MI EVENTO EN UN HOTEL TODO INCLUIDO?',
                        content: '<p> LA EXPERIENCIA QUE OFRECEN LOS HOTELES T.I. ES EL MARCO PERFECTO PARA LA REALIZACIÓN DE UN EVENTO TAN IMPORTANTE COMO TU BODA.</p>\n' +
                            '                      <p> AL NO TENER QUE PREOCUPARSE POR ASPECTOS COMO EL ALOJAMIENTO, TRANSPORTE, COMIDAS, BEBIDAS Y ENTRETENIMIENTO, TUS FAMILIARES Y AMIGOS SE PODRÁN CONCENTRAR EN DISFRUTAR DE UNAS MARAVILLOSAS VACACIONES Y DE COMPARTIR GRANDES MOMENTOS CON USTEDES.</p>\n' +
                            '                      <p> DENTRO DE UN HOTEL TODO INCLUIDO, LA DECISIÓN MAS DIFÍCIL QUE TUS INVITADOS TENDRÁN QUE TOMAR, ES SI VAN AL MAR O A LA PISCINA Y SI CENAN EN EL RESTAURANTE FRANCÉS O EN EL CARNES. ES EL ESCENARIO PERFECTO PARA QUE TODOS DISFRUTEN SIN NINGÚN TIPO DE COMPLICACIÓN.</p>\n' +
                            '                      <p> ADEMÁS EL HOTEL TODO INCLUIDO NIVELA EL PODER DE ADQUISICIÓN Y DE COMPRA DE TODOS TUS INVITADOS. AUNQUE A CADA UNO DE ELLOS LE COSTO DIFERENTE CANTIDAD DE ESFUERZO PAGAR SU RESERVACIÓN… UNA VEZ ADENTRO, TODOS SON IGUALES¡ ESO PERMITE QUE LA UNIÓN DE TU NUEVA FAMILIA SEA MAS SENCILLA Y NATURAL.</p>\n' +
                            '                      <p> UN HOTEL TODO INCLUIDO ES EL LUGAR MÁS SEGURO PARA TENER UN EVENTO INCREÍBLE. LOS HOTELES TIENEN PLAN B Y PLAN C PARA TODA SITUACIÓN Y SON LOS EXPERTOS EN LOGRAR DAR SERVICIOS DE CALIDAD, A CIENTOS O MILES DE PERSONAS AL DÍA, TODOS LOS DÍAS.</p>\n' +
                            '                      <p> ADEMÁS, EL NEGOCIO DEL HOTEL ES EL ALOJAMIENTO, NO TU EVENTO. ESO PERMITE QUE SU PAQUETE DE BODA Y TODO LO QUE NECESITAS PARA TU EVENTO SEA MUY ECONÓMICO O INCLUSO GRATIS, POR LO QUE PODRÁS MAXIMIZAR EL PRESUPUESTO QUE TIENES PARA TU EVENTO.</p>',
                    },
                    {
                        title: '¿ENTONCES LOS INVITADOS PAGAN MI BODA?',
                        content: '<p> NO. UNA BODA MODERNA CONSISTE EN UNA CEREMONIA CIVIL, RELIGIOSA O SIMBÓLICA, SEGUIDA DE UNA FIESTA PRIVADA DE 5 A 6 HORAS, DONDE LOS ASISTENTES PODRÁN DISFRUTAR DE COMIDA, BEBIDAS, POSTRES, Y MÚSICA PARA BAILAR Y DIVERTIRSE. AUNQUE EL MONTO TOTAL SERÁ MÁS BAJO QUE UNA BODA TRADICIONAL, LOS NOVIOS AÚN TIENEN QUE PAGAR POR TODOS ESTOS CONCEPTOS.</p>\n' +
                            '                      <p> LO QUE LOS INVITADOS ESTÁN PAGANDO, ES SU ESTADÍA EN UN CUARTO DE UN HOTEL CON ALIMENTOS Y BEBIDAS LAS 24 HORAS, ADEMÁS DEL ACCESO A LOS SERVICIOS, INSTALACIONES Y AMENIDADES DEL HOTEL. ES DECIR, ELLOS ESTÁN PAGANDO SUS VACACIONES… QUE HAN DECIDIDO TOMAR, EN CONJUNTO CON EL GRUPO DE TU BODA, PARA NO PERDERSE ESTE GRAN EVENTO Y MAXIMIZAR SU EXPERIENCIA VACACIONAL.</p>',
                    },
                    {
                        title: '¿TODOS LOS INVITADOS TIENEN QUE HOSPEDARSE EN EL HOTEL?',
                        content: '<p> NO. LOS HOTELES NOS PERMITEN POR LO GENERAL, QUE HASTA EL 20% DE LOS ASISTENTES A TU EVENTO NO SEAN HUÉSPEDES DEL HOTEL. CON MUCHA EXPERIENCIA TE PODEMOS DECIR, QUE ESTE PORCENTAJE SUELE SER SUFICIENTE PARA CUBRIR CON LOS CASOS DE INVITADOS QUE POR ALGUNA RAZÓN, NO DESEAN HOSPEDARSE EN EL HOTEL PERO DESEAN ASISTIR A TU BODA. SI TU CASO ES MUY ESPECIAL, PODEMOS ENCONTRAR UN HOTEL QUE ESTE DISPUESTO A NEGOCIAR ESTE PORCENTAJE.</p>\n' +
                            '                      <p> LOS INVITADOS QUE NO ESTÉN HOSPEDADOS EN EL HOTEL, DEBERÁN DE PAGAR UN WEDDING PASS. NORMALMENTE, ESTE PASE LES DA ACCESO AL HOTEL 1 HORA ANTES DE TU EVENTO Y LES PIDE RETIRARSE DEL HOTEL AL FINALIZAR LAS ACTIVIDADES DE TU BODA.</p>\n' +
                            '                      <p> EL COSTO VARIA DE HOTEL A HOTEL, PERO ESTA DIRECTAMENTE RELACIONADO AL COSTO POR NOCHE DE HOSPEDAJE. REGULARMENTE, EL COSTO DEL WEDDING PASS, SERÁ CERCANO AL 60% DEL COSTO DEL HOSPEDAJE.</p>',
                    },
                    {
                        title: '¿QUÉ ES LA ESTADÍA MÍNIMA? ¿TODOS LOS INVITADOS TIENEN QUE CUMPLIRLA?',
                        content: '<p> PARA PODER OFRECERTE LOS BENEFICIOS PARA TU BODA Y UNA BUENA TARIFA DE HOSPEDAJE PARA TUS INVITADOS, EL HOTEL TODO INCLUIDO REQUIERE DE ACOMODAR CIERTO MÍNIMO DE CUARTOS NOCHES.</p>\n' +
                            '                      <p> ES POR ESO QUE EN TU CONTRATO ESTARÁ ESPECIFICADO, SI EL MÍNIMO DE ESTADÍA ES DE 2 NOCHES O DE 3 NOCHES. ES MÁS FÁCIL CONSEGUIR BENEFICIOS ADICIONALES Y REGALOS, SI CONTRATAS PAQUETES CON ESTADÍA MÍNIMA DE 3 NOCHES. ADEMÁS TUS INVITADOS DISFRUTARÁN DE UNA EXPERIENCIA MAS COMPLETA Y DIVERTIDA.. NADIE QUIERE SALIR DEL HOTEL EL DÍA DE LA TORNABODA¡</p>\n' +
                            '                      <p> DE TODAS FORMAS, SABEMOS QUE POR DIFERENTES MOTIVOS, NO TODOS LOS ASISTENTES PODRÁN ACOMPAÑARTE TODAS LAS NOCHES DEL CONTRATO. ES POR ESO, QUE UNA VEZ CUBIERTO EL NUMERO DE HABITACIONES CONTRATADAS DEL HOTEL, SERÁ POSIBLE CONTRATAR HABITACIONES POR UN MENOR NÚMERO DE NOCHES A LAS DE LA ESTADÍA MÍNIMA.</p>\n' +
                            '                      <p> PARA OPERAR ESTE ASPECTO, PONEMOS A LOS INTERESADOS EN ASISTIR UN MENOR NÚMERO DE NOCHES, EN UNA LISTA DE ESPERA. APENAS LOGREMOS LA META DEL CONTRATO CON EL HOTEL, COMENZAMOS A REPORTAR ESTE TIPO DE RESERVACIÓN.</p>',
                    },
                    {
                        title: '¿QUÉ PASA SI NO LLEGO AL MÍNIMO DE HABITACIONES DE MI CONTRATO?',
                        content: '<p> EN EL PROCESO DE APOYARTE CON LA SELECCIÓN DEL HOTEL, REALIZAMOS CÁLCULOS EN BASE A LA EFICIENCIA DE ASISTENCIA QUE SOLEMOS LOGRAR Y LA COMPARAMOS CON LA DIMENSIÓN DE TU LISTA DE INVITADOS, PARA SELECCIONAR UN PAQUETE DE HOTEL, EN EL QUE SEA REALISTA EL QUE PODAMOS ALCANZAR CON EL MÍNIMO.</p>\n' +
                            '                      <p> SIN EMBARGO, ES POSIBLE QUE NO ESTEMOS LLEGANDO AL MÍNIMO. COMO LA PAREJA ES RESPONSABLE DEL PAGOS DE ESAS HABITACIONES, EN ESTOS CASOS APLICAMOS ALGUNA DE LAS SIGUIENTES ESTRATEGIAS DE ACUERDO A CADA CASO:</p>\n' +
                            '                      <ul class="list-unstyled">\n' +
                            '                        <li>• NEGOCIAMOS CON EL HOTEL EL MEJOR DEAL DE REDUCCIÓN POSIBLE.</li>\n' +
                            '                        <li>• ANALIZAMOS EL ESTADO DE LOS CUARTOS/NOCHE, PARA VER SI ESTAMOS TENIENDO POCAS HABITACIONES PERO POR MUCHAS NOCHES.</li>\n' +
                            '                        <li>• DESARMAMOS HABITACIONES EN OCUPACIÓN TRIPLE, PARA CONVERTIRLAS EN DOBLES Y CONSEGUIR MAYOR CONTEO DE HABITACIONES.</li>\n' +
                            '                        <li>• CONVERTIMOS HABITACIONES SINGLES A OCUPACIÓN DOBLE PARA QUE CUENTEN EN EL BLOQUEO.</li>\n' +
                            '                        <li>• OFRECEMOS LAS HABITACIONES A UN PRECIO PREFERENCIAL A NUESTRA BASE DE DATOS DE CLIENTES.</li>\n' +
                            '                        <li>• REMATAMOS LAS HABITACIONES EN FACEBOOOK, PAGANDO ADS PARA ACOMODARLAS.</li>\n' +
                            '                      </ul>\n' +
                            '                      <p> AUNQUE NO TE PODEMOS GARANTIZAR QUE ESTO NO OCURRIRÁ, NUESTRA EXPERIENCIA Y EXCELENTE RELACIÓN CON LOS HOTELES, NOS PERMITIRÁN TOMAR LAS MEJORES ACCIONES PARA RESOLVER LA SITUACIÓN.</p>\n' +
                            '                      <p> ADEMÁS, CON NUESTRO SOFTWARE DE GESTIÓN DE EVENTOS, LOGRAMOS UN ALTO NIVEL DE PORCENTAJE DE ASISTENCIA, YA QUE PARA LOS INVITADOS ES MUY SENCILLO CONOCER LOS DETALLES DE TU BODA, RESERVAR CON SOLO 1500 PESOS E IR PAGANDO POCO A POCO SIN COMPLICACIONES.</p>',
                    },
                    {
                        title: '¿LOS PAQUETES INCLUYEN EL VUELO Y EL TRANSPORTE?',
                        content: '<p> SOLEMOS OFRECER TODAS LAS OPCIONES, PARA QUE TUS INVITADOS SELECCIONEN AQUEL PAQUETE QUE MEJOR SE AJUSTE A SUS NECESIDADES. OFRECEMOS SOLO LA HABITACIÓN Y PAQUETES CON TRANSPORTE TERRESTRE E INCLUSO AVIÓN INCLUIDO A PRECIOS MUY COMPETITIVOS.</p>\n' +
                            '                      <p> LOS PRECIOS QUE ENVIAMOS EN LAS PROPUESTAS Y COTIZACIONES, CORRESPONDEN AL HOSPEDAJE POR PERSONA POR NOCHE, EN BASE DOBLE Y EN MODALIDAD TODO INCLUIDO.</p>',
                    },
                    {
                        title: '¿HACEN EVENTOS EN HOTELES CON OTRO PLAN? ¿O EN OTRA LOCACIÓN FUERA DEL HOTEL?',
                        content: '<p> ORGANIZAMOS BODAS EN CUALQUIER CIUDAD Y LOCACIÓN. LO ÚNICO A CONSIDERAR ES QUE CUANDO NO ES EN UN HOTEL TODO INCLUIDO, NUESTROS HONORARIOS DEBERÁN SER CUBIERTOS POR LA PAREJA.</p>\n' +
                            '                      <p> OFRECEMOS PAQUETES A MEDIDA DE LAS NECESIDADES DE CADA EVENTO Y PODEMOS ENCARGARNOS DEL PROCESO DE COORDINACIÓN DEL EVENTO Y EL MANEJO DEL GRUPO. CON MUCHO GUSTO PREPARAREMOS UNA COTIZACIÓN.</p>\n' +
                            '                      <p> TAMBIÉN OFRECEMOS NUESTRO SOFTWARE DE GESTIÓN DE EVENTOS, DISPONIBLE A LA RENTA PARA QUE PUEDAS USARLO EN LA OPERACIÓN DE TU GRUPO. TENEMOS PLANES DESDE 8,900 PESOS, POR 1 AÑO DE SERVICIOS.</p>',
                    },
                    {
                        title: '¿CUÁL ES SU METODOLOGÍA DE OPERACIÓN DE MI BODA?',
                        content: '<p> TODO NUESTRO PERSONAL ESTÁ CERTIFICADO EN OPERACIÓN Y PLANEACIÓN DE BODAS EN DESTINO POR ORGANIZACIONES COMO SECTUR, ABC, WEDDINGS INTERNATIONAL Y AEBD.</p>\n' +
                            '                      <p> CADA EVENTO ES ATENDIDO POR UN MÍNIMO DE 5 PERSONAS DE NUESTRO STAFF: EL DIRECTOR, UNA WEDDING PLANNER, 2 EJECUTIVAS DE CALL CENTER Y 1 EJECUTIVO DE ASISTENCIA TÉCNICA.</p>\n' +
                            '                      <p> AL INICIO DE LA OPERACIÓN, CREAREMOS UN GRUPO DE WHATS APP, DONDE TODOS ESTAREMOS PENDIENTES DE SUS REQUERIMIENTOS, PARA RESPONDERLOS MUY RÁPIDAMENTE.</p>\n' +
                            '                      <p> NUESTRA METODOLOGÍA DE COORDINACIÓN CONTEMPLA 30 PASOS PARA LA OPERACIÓN DE BODAS.</p>\n' +
                            '                      <p> LA PAREJA SIEMPRE SABRÁ EN QUE PASO NOS ENCONTRAMOS Y LOS TIEMPOS APROXIMADOS DE CADA ETAPA, LO QUE LES PERMITE ESTAR TRANQUILOS, DE QUE TODO ESTÁ BAJO CONTROL.</p>\n' +
                            '                      <p> SIN EMBARGO SOMOS FLEXIBLES Y ADAPTAMOS NUESTRO PLAN A LAS NECESIDADES ESPECIFICAS DE LA PAREJA, ASÍ QUE NO TE PREOCUPES, ESTAS EN MANOS DE PROFESIONALES CON AMPLIA EXPERIENCIA EN LA OPERACIÓN Y GESTIÓN DE EVENTOS EN DESTINO.</p>',
                    },
                    {
                        title: '¿CUÁL ES EL PROCESO PARA CONTRATAR MI BODA CON USTEDES?',
                        content: '<p> EL PROCESO DE CONFIRMACIÓN DEL EVENTO SE COMPONE DE 2 FASES: EL BLOQUEO DE LA FECHA Y EL BLOQUEO DE LAS HABITACIONES.</p>\n' +
                            '                      <p><b> BLOQUEO DE FECHA:</b></p>\n' +
                            '                      <p> LO MAS IMPORTANTE ES SEPARAR RÁPIDAMENTE TU FECHA. RECUERDA QUE MUCHAS PAREJAS DE TODO EL MUNDO, HAN ENCONTRADO QUE LA PROPUESTA Y TARIFA Y DEL HOTEL QUE HAN SELECCIONADO, ES MUY CONVENIENTE, ASÍ QUE NECESITAMOS APARTAR LA FECHA Y EL HORARIO.</p>\n' +
                            '                      <p> LA MAYORÍA DE LOS HOTELES NOS PIDEN $5,000 PESOS PARA SEPARAR TU FECHA. ESTE PAGO SE ABONARÁ A TU CUENTA MAESTRA. EN LA SIGUIENTE PREGUNTA NOS EXTENDEREMOS SOBRE ESTE CONCEPTO.</p>\n' +
                            '                      <p> DESPUÉS DE BLOQUEAR LA FECHA, RECIBIREMOS UN CORREO DEL HOTEL, CON LOS DETALLES DEL EVENTO, PARA TU REVISIÓN Y APROBACIÓN.</p>\n' +
                            '                      <p> EN ESTE CORREO ESTARÁ DEFINIDA LA FECHA DEL EVENTO, LA FECHA DE ENTRADA Y SALIDA DEL GRUPO, LA TARIFA PACTADA PARA CADA TIPO DE OCUPACIÓN, EL NÚMERO Y TIPO DE HABITACIÓN A SER BLOQUEADO EL PAQUETE A SER APLICADO, LAS CORTESÍAS O BENEFICIOS PARA LA PAREJA Y LOS HORARIOS Y TIPOS DE CEREMONIA.</p>\n' +
                            '                      <p> UNA VEZ QUE ESTEMOS DE ACUERDO CON ESTOS DETALLES, EL HOTEL COMENZARÁ A PREPARAR EL CONTRATO DEL BLOQUEO DE HABITACIONES.</p>\n' +
                            '\n' +
                            '                      <p><b> BLOQUEO DE HABITACIONES:</b></p>\n' +
                            '                      <p> ¿TE IMAGINAS QUE EL HOTEL NO TUVIERA SUFICIENTES HABITACIONES PARA TUS INVITADOS? ¿O QUE LA TARIFA FUERA DINÁMICA Y TU INVITADO NO TUVIERA CERTEZA DE CUANTO PAGAR POR ASISTIR?</p>\n' +
                            '                      <p> PARA EVITAR ESAS COMPLICACIONES, EL HOTEL REALIZA UN BLOQUEO DE HABITACIONES. EL BLOQUEO DEFINE EL NÚMERO Y EL PRECIO FIJO DE LAS HABITACIONES, A LO LARGO DE TODOS LOS MESES PREVIOS A TU BODA, HASTA 30 DÍAS ANTES DE LA FECHA DEL EVENTO Y ESTARÁ CLARAMENTE DEFINIDO EN TU CONTRATO CON EL HOTEL.</p>\n' +
                            '                      <p> ADEMÁS DEL BLOQUEO DE HABITACIONES, HAY MUCHOS DETALLES QUE SERÁN INCLUIDOS EN TU CONTRATO; COMO EL PLAN PAGOS, LAS POLÍTICAS DE REDUCCIÓN Y CANCELACIÓN, LOS COSTOS DE LOS WEDDING PASS Y DE LOS FEES POR EL DESPLAZAMIENTO DE PROVEEDORES DEL HOTEL, ENTRE MUCHOS OTROS ASPECTOS DE LA RELACIÓN COMERCIAL QUE SE ENTABLARÁ ENTRE LAS PARTES.</p>\n' +
                            '                      <p> LOS CONTRATOS CON LOS HOTELES NO PUEDEN SER MODIFICADOS, NUESTRO TRABAJO COMO TU AGENCIA EN ESTE PUNTO, ES AYUDARTE A ENTENDER LAS REGLAS DEL JUEGO, PARA QUE TENGAN CLARAS LAS CONDICIONES DEL HOTEL, EN CADA UNO DE LOS ESCENARIOS POSIBLES.</p>\n' +
                            '                      <p> TE ENVIAREMOS UN CONTRATO DEL HOTEL CON MUCHA ANTICIPACIÓN, CON EL FIN DE QUE PODAMOS RESOLVER TUS DUDAS CON TIEMPO; YA QUE CUANDO LLEGUE TU CONTRATO, SERÁ NECESARIO FIRMARLO Y PAGARLO EN MUY POCO TIEMPO.</p>\n' +
                            '                      <p> PARA CONFIRMAR TU BODA, AL FIRMAR TU CONTRATO, SERÁ NECESARIO EL PAGO DE UN ANTICIPO POR EL BLOQUEO DE LAS HABITACIONES.</p>\n' +
                            '                      <p> DEPENDIENDO EL HOTEL, ESTE ANTICIPO PUEDE IR DESDE EL 10% HASTA EL 25% DEL TOTAL DE LAS HABITACIONES. ES POSIBLE QUE EL HOTEL TAMBIÉN SOLICITE UN PORCENTAJE DEL COSTO DEL PAQUETE DE BODA PARA SEPARAR LA LOCACIÓN. AL IGUAL QUE EL IMPORTE POR BLOQUEO DE FECHA, ESTE ANTICIPO SE VERA REFLEJADO COMO UN ABONO A TU CUENTA MAESTRA.</p>',
                    },
                    {
                        title: 'NO CUENTO CON ESA CANTIDAD EN ESTE MOMENTO… ¿QUÉ PODEMOS HACER?',
                        content: '<p> ALGUNOS HOTELES PUEDEN DARNOS LA OPCIÓN DE UN PLAN DE PAGOS DEL ANTICIPO, SI ES QUE LA PAREJA FUNDAMENTA CORRECTAMENTE LOS TIEMPOS DE ESTE PLAN.</p>\n' +
                            '                      <p> PARA ESTO SERÁ NECESARIO QUE LA PAREJA ESCRIBA UN CORREO DIRIGIDO AL EQUIPO DE BODAS DEL HOTEL, OFRECIENDO UN PLAN DE PAGOS CON MONTOS Y TIEMPOS ESPECÍFICOS.</p>\n' +
                            '                      <p> NO PODEMOS GARANTIZARTE QUE TU PLAN DE PAGOS SERÁ APROBADO, PERO SI REALIZAS UNA OFERTA QUE INCLUYA EL 50% AL 60% DEL MONTO DEL ANTICIPO Y UN PLAN DE 30 A 45 DÍAS PARA PAGAR EL SALDO RESTANTE, ES MUY POSIBLE QUE EL HOTEL ACEPTE TU PROPUESTA.</p>\n' +
                            '                      <p> ESO SÍ, DEBERÁN CUMPLIR CON LOS MONTOS Y FECHAS ESTABLECIDAS O CORREN EL RIESGO DE PERDER LA FECHA Y EL ANTICIPO ENTREGADO AL HOTEL.</p>\n' +
                            '                      <p> OTRO CAMINO ES APOYARNOS DE AQUELLOS FAMILIARES O AMIGOS CON LOS QUE MÁS CONFIANZA TENGAS.</p>\n' +
                            '                      <p> ES POSIBLE QUE CREEMOS TU PAGINA WEB PERSONALIZADA Y ACTIVEMOS EL SISTEMA, PARA QUE ESTAS PERSONAS DE CONFIANZA, REALICEN UN ANTICIPO DE SU RESERVACIÓN Y CON ESTE DINERO RECAUDADO, LOGREMOS REALIZAR EL PAGO DEL ANTICIPO DEL HOTEL.</p>\n' +
                            '                      <p> COMPARTE CON NOSOTROS EL FLUJO DE CAJA Y PRESUPUESTO DEL QUE DISPONES, HAREMOS LO POSIBLE POR AYUDARTE A LOGRAR TU BLOQUEO DE LA MEJOR FORMA.</p>',
                    },
                    {
                        title: '¿QUÉ ES LA CUENTA MAESTRA Y CÓMO FUNCIONA?',
                        content: '<p> LA CUENTA MAESTRA DEL EVENTO, ES LA CANTIDAD QUE DEBERÁN DE PAGAR LA PAREJA POR SU BODA. ESTA FORMADA POR 3 ITEMS QUE TE DETALLAMOS A CONTINUACIÓN:</p>\n' +
                            '                      <p> PAQUETE DE BODA.- ES EL COSTO A PAGAR POR EL PAQUETE DEL EVENTO CONTRATADO AL HOTEL. SI EL PAQUETE ES EN CORTESÍA, NO CARGAREMOS NINGÚN MONTO EN ESTE ÍTEM DE TU CUENTA MAESTRA.</p>\n' +
                            '                      <p> ESTADÍA Y CUARTOS PAGADOS POR LOS NOVIOS.- ESTE IMPORTE CORRESPONDE AL COSTO DEL NÚMERO DE NOCHES QUE LA PAREJA PASARÁ EN EL HOTEL. SI EL HOTEL TE OFRECE ALGÚN TIPO DE CORTESÍA DE HOSPEDAJE, EL IMPORTE SERÁ DESCONTADO. LOS NOVIOS TAMBIÉN PUEDEN DECIDIR CUBRIR EL COSTO O PARTE DEL COSTO DE LA ESTADÍA DE ALGÚN INVITADO. ESTE ES EL LUGAR DE LA CUENTA MAESTRA DONDE EST COSTO SERÍA CONTEMPLADO.</p>\n' +
                            '                      <p> ADICIONALES AL PAQUETE Y/O FEES DE PROVEEDORES. - AQUÍ SUMAREMOS TODO LO QUE DECIDAS CONTRATAR PARA TU BODA, QUE SEA ADICIONAL AL PAQUETE DE BODAS. TAMBIÉN SERÁN INCLUIDOS LOS FEES POR PROVEEDORES EXTERNOS, EN CASO DE EXISTIR.</p>\n' +
                            '                      <p> LA CUENTA MAESTRA DE LOS NOVIOS SE SUELE DEFINIR CON 60 DÍAS ANTES DE LA FECHA DEL EVENTO (CUANDO YA SE HAN DEFINIDO TODOS LOS SERVICIOS A SER CONTRATADOS) Y DEBERÁ ESTAR SALDADA 30 DÍAS ANTES DEL EVENTO.</p>\n' +
                            '                      <p> LOS IMPORTES DE BLOQUEO DE FECHA Y DEL BLOQUEO DE HABITACIONES, SE APLICAN COMO ABONO A TU CUENTA MAESTRA.</p>',
                    },
                    {
                        title: '¿ES CONVENIENTE CONTRATAR A LOS PROVEEDORES DEL HOTEL?',
                        content: '<p> LOS PROVEEDORES DE LOS HOTELES, HAN SIDO CUIDADOSAMENTE SELECCIONADOS POR SU CALIDAD, PRESTIGIO Y OFERTA COMPETITIVA. POR LO QUE CONTRATAR A LOS PROVEEDORES DEL HOTEL ES UNA GARANTÍA DE NOVIOS FELICES. NO SOLO CONOCEN EL HOTEL DE MEMORIA, SINO QUE ADEMÁS TIENEN UNA EXCELENTE COMUNICACIÓN CON TUS COORDINADORES, LO QUE FACILITARÁ EL PROCESO DE OPERACIÓN.</p>\n' +
                            '                      <p> PERO NO TE PREOCUPES, CONTAMOS CON UN EQUIPO DE PROVEEDORES EXTERNOS MUY PROFESIONAL Y DEDICADO, PARA QUE PUEDAS COMPARAR LAS OPCIONES Y SELECCIONAR LA QUE MAS TE CONVENGA. LO IMPORTANTE ES QUE LA BODA CON LA QUE HAS SOÑADO SEA UNA REALIDAD¡</p>',
                    },
                    {
                        title: '¿ES INDISPENSABLE GRABAR EL VIDEO DE SAVE THE DATE?',
                        content: '<p> EL VIDEO SAVE THE DATE QUE ENVIAMOS A TU LISTA DE INVITADOS, VIA MAILING Y VIA MENSAJE DE TEXTO, TIENE COMO OBJETIVO TRANSMITIR A TUS INVITADOS, LO IMPORTANTE QUE ES PARA USTEDES, QUE ASISTAN A SU BODA.</p>\n' +
                            '                      <p> ES POR ESO QUE SUGERIMOS QUE SEA UN MENSAJE SENTIDO Y DE CORAZÓN… UNA INVITACIÓN CON TUS PROPIAS PALABRAS, QUE REFLEJE LA PERSONALIDAD DE LA PAREJA Y QUE SIRVA DE PROBADITA DE LO QUE PUEDEN ESPERAR DE TU BODA.</p>\n' +
                            '                      <p> NO ES NECESARIA UNA GRAN PRODUCCIÓN Y HAY MUCHOS FORMATOS, QUE PAREJAS PREVIAS HAN CREADO, QUE PUEDEN SERVIR DE INSPIRACIÓN, PARA QUE ENCUENTREN UNO CON EL QUE SE IDENTIFIQUEN.</p>\n' +
                            '                      <p> VISITA NUESTRO CANAL DE YOU TUBE PARA DARTE UNA IDEA DE LAS POSIBILIDADES PARA ESTE VIDEO.</p>',
                    },
                    {
                        title: '¿POR QUE MEDIOS CONTACTARÁN A MIS INVITADOS?',
                        content: '<p> HASTA CON SEÑALES DE HUMO SI ES NECESARIO¡ 😊</p>\n' +
                            '                      <p> AL CONTRATAR TU BODA CON NOSOTROS, TE PEDIREMOS QUE LLENES UN FORMATO DE EXCEL, CON TODA LA LISTA DE INVITADOS. AHORA SI… NO OLVIDES A NADIE¡</p>\n' +
                            '                      <p> EN ESTA LISTA, EL NÚMERO CELULAR DEL INVITADO ES OBLIGATORIO Y EL CORREO ELECTRÓNICO ES ALTAMENTE DESEABLE. TAMBIÉN PODRÁS COLOCAR EL PARENTESCO, LA IMPORTANCIA DEL INVITADO Y LAS OBSERVACIONES QUE CREAS NOS PUEDEN AYUDAR A HACER UN MEJOR TRABAJO.</p>\n' +
                            '                      <p> CUANDO TODO ESTE LISTO Y AUTORIZADO POR USTEDES, HAREMOS EL ENVÍO DE LOS CORREOS Y LOS SMS DE SAVE THE DATE. SERÁ MUY FÁCIL AVISARLES A TODOS DE LOS DETALLES DE TU BODA¡</p>\n' +
                            '                      <p> DE 15 A 20 DÍAS DESPUÉS DEL ENVÍO DEL SAVE THE DATE, COMENZAREMOS CON EL PROCESO DE LAS LLAMADAS TELEFÓNICAS.</p>',
                    },
                    {
                        title: '¿QUÉ PASA SI ALGUIEN NO CONTESTA? O SI NO LE LLEGO EL MAIL?',
                        content: '<p> NO TE PREOCUPES ESTO ES NORMAL.</p>\n' +
                            '                      <p> UN ALTO PORCENTAJE DE LOS CORREOS ELECTRÓNICOS ACABARA EN OTRAS BANDEJAS INCLUIDA EL SPAM. ES POR ESO QUE USAMOS EL SERVICIO DE SMS COMO MÉTODO DE CONTACTO PRINCIPAL, PUES TIENE UN 97% DE EFECTIVIDAD Y APERTURA.</p>\n' +
                            '                      <p> CADA VEZ ES MÁS COMÚN, QUE LAS PERSONAS NO CONTESTEN LLAMADAS DE NÚMEROS DESCONOCIDOS. ES POR ESO, QUE DESPUÉS DE MARCAR 2 VECES CONSECUTIVAS A UN INVITADO (EL BANCO NO MARCA 2 VECES SEGUIDAS ) LE ENVIAMOS UN MENSAJE DE WHATS APP, DONDE NOS PRESENTAMOS Y NOS PONEMOS A SUS ÓRDENES, LE PEDIMOS QUE GUARDE NUESTRO NÚMERO Y PEDIMOS LA CONFIRMACIÓN DE QUE HAN RECIBIDO EL CORREO ELECTRÓNICO Y/O EL SMS CON LA INFORMACIÓN DEL EVENTO.</p>\n' +
                            '                      <p> NUESTRO CALL CENTER FUNCIONA DE FORMA MUY EFICIENTE. PARA PODER PASAR A LA SIGUIENTE LLAMADA DE LA AGENDA, NUESTRO SUPER EJECUTIVA SOLO PUEDE REALIZAR UNA DE ESTAS 3 ACCIONES:</p>\n' +
                            '                      <p> REPORTAR QUE NO ASISTE.- EN ESTE CASO, DEJAREMOS DE CONTACTARLO. SIN EMBARGO, EL PODRÁ REALIZAR UNA RESERVACIÓN EN CUALQUIER MOMENTO, DESDE TODOS LOS MEDIOS DISPONIBLE O PODRÁ COMUNICARSE CON NOSOTROS, PARA RECIBIR APOYO CON SU RESERVACIÓN.</p>\n' +
                            '                      <p> RESERVAR UNA HABITACIÓN.- TOMAREMOS LOS DATOS PRINCIPALES DE TU INVITADO, PARA CONVERTIRLO EN UN ASISTENTE. RECIBIRÁ LA CONFIRMACIÓN DE SU RESERVA Y LA FICHA PARA REALIZAR EL PAGO, VIA EMAIL Y SMS DE FORMA INSTANTÁNEA.</p>\n' +
                            '                      <p> AGENDAR UNA NUEVA LLAMADA.- ES POSIBLE QUE TU INVITADO AÚN NO PUEDA CONFIRMAR SU ASISTENCIA. LE PREGUNTAREMOS CUANDO DESEA RECIBIR UNA LLAMADA DE SEGUIMIENTO. SI NO PUDO TOMAR LA LLAMADA O NO PUDO DARNOS UNA NUEVA FECHA, ASIGNAREMOS NOSOTROS UNA.</p>',
                    },
                    {
                        title: '¿CÓMO PUEDO SABER SI YA CONFIRMÓ UN INVITADO?',
                        content: '<p> A LO LARGO DE LA PLANEACIÓN DE TU BODA, RECIBIRÁS 3 COMPLETOS REPORTES, CON EL DETALLE DE LAS LLAMADAS Y CONTACTOS A TUS INVITADOS, ASÍ COMO EL ROOM LIST CON EL DETALLE DE LAS RESERVACIONES Y SUS PAGOS.</p>\n' +
                            '                      <p> SIN EMBARGO, LA PAREJA CUENTA CON UNA CONSOLA DE ADMINISTRACIÓN DEL EVENTO, DONDE TENDRÁS CASI TODAS LAS FUNCIONES DEL SOFTWARE, CON LAS QUE CUENTA NUESTRO PERSONAL, CON EL FIN DE QUE PUEDAS REALIZAR UNA GRAN CANTIDAD DE CONSULTAS Y ACCIONES.</p>',
                    },
                    {
                        title: '¿QUÉ OTRAS FUNCIONES TIENE LA CONSOLA DE ADMINISTRACIÓN?',
                        content: '<p> ESTA DIVIDIDA EN 5 SECCIONES PRINCIPALES.</p>\n' +
                            '                      <p> LISTA DE INVITADOS.- PODRÁS AGREGAR NUEVOS INVITADOS DE FORMA FÁCIL, REVISAR EL HISTORIAL DE LAS LLAMADAS TELEFÓNICAS Y LAS OBSERVACIONES DE CADA LLAMADA, CONFIRMAR SI RECIBIÓ, ABRIÓ Y CLICÓ EL MAIL DE INVITACIÓN, EDITAR SUS DATOS, REALIZAR UNA RESERVACIÓN A SU NOMBRE, PEDIR QUE LO CONTACTEMOS O REPORTAR QUE NO ASISTIRÁ.</p>\n' +
                            '                      <p> RESERVACIONES.- PODRÁS VER QUIENES HAN REALIZADO UNA RESERVACIÓN PARA ASISTIR A TU EVENTO. CUALES SON LOS DETALLES DE SU RESERVA Y SU ESTADO DE CUENTA, CON LOS PAGOS REALIZADOS Y LOS QUE SE ENCUENTRAN EN ESPERA. TAMBIÉN PODRÁS MODIFICAR SU RESERVACIÓN, SUS DATOS, REENVIARLE SU CLAVE DE CONFIRMACIÓN O SOLICITAR QUE PROCESEMOS SU CANCELACIÓN.</p>\n' +
                            '                      <p> CUENTA MAESTRA.- EN ESTA SECCIÓN ESTÁ EL DETALLE DE LOS ABONOS QUE HAS REALIZADO A TU CUENTA MAESTRA Y LOS SERVICIOS Y PAQUETES QUE HAS CONTRATADO. TAMBIÉN PODRÁS REPORTAR VOUCHERS DE NUEVOS DEPÓSITOS.</p>\n' +
                            '                      <p> MENSAJES Y NOTIFICACIONES. – AQUÍ PODRÁS VER EL DETALLE DE MUCHAS DE LAS ACCIONES QUE REALIZAMOS CON TUS INVITADOS, POR EJEMPLO: CUANDO APROBEMOS UNA HABITACIÓN, CUANDO HAY UN NUEVO VOUCHER O UN MENSAJE DE UN INVITADO. TAMBIÉN PODRÁS CAMBIAR LA FOTO DE PORTADA Y LA GALERÍA DEL EVENTO, CAMBIAR TU CONTRASEÑA Y EDITAR EL MENSAJE DE INVITACIÓN.</p>\n' +
                            '                      <p> CRONOGRAMA.- PODRÁS AGREGAR ACTIVIDADES AL ITINERARIO DE TU BODA, PARA QUE TODOS TUS INVITADOS SEPAN LOS PLANES QUE TIENES PARA CADA MOMENTO DEL VIAJE. NO TE PREOCUPES, EL CRONOGRAMA DEFINITIVO, SERÁ ENVIADO JUNTO CON EL CUPÓN DE ACCESO UNOS DÍAS ANTES DEL EVENTO.</p>',
                    },
                    {
                        title: '¿CUAL ES SU POLÍTICA DE PRIVACIDAD DE DATOS PERSONALES?',
                        content: '<p> SOLO CONTACTAREMOS A TUS INVITADOS, CON EL FIN DE HACERLES LLEGAR LOS DETALLES DE TU BODA Y DARLE SEGUIMIENTO A SU ASISTENCIA. NO COMPARTIMOS NI COMPARTIREMOS ESTA INFORMACIÓN CON NADIE.</p>\n' +
                            '                      <p> UNA VEZ FINALIZADO EL EVENTO, BORRAREMOS LA BASE DE DATOS, CON EXCEPCIÓN DE AQUELLOS QUE HAYAN MANIFESTADO QUE DESEAN RECIBIR NUESTRO NEWSLETTER CON PROMOCIONES Y DESCUENTOS EN VIAJES.</p>',
                    },
                    {
                        title: '¿CUÁLES SON SUS HORARIOS DE ATENCIÓN?',
                        content: '<p> NUESTRO EQUIPO PODRÁ ATENDER A TUS INVITADOS, DE LUNES A VIERNES DE 9 AM A 6 PM.</p>\n' +
                            '                      <p> A TRAVÉS DEL GRUPO DE WHATS APP QUE TENEMOS CON LA PAREJA, PODEMOS PRESTAR SERVICIO HASTA LAS 8 PM DE LUNES A VIERNES Y LOS SÁBADOS DE 10 AM A 1 PM</p>\n' +
                            '                      <p> ES MUY DIFÍCIL QUE CON TANTOS MESES DE PLANEACIÓN ALGO SEA “URGENTE” SI ES EL CASO, CON MUCHO GUSTO TE ATENDEREMOS EN CUALQUIER MOMENTO Y LO MÁS PRONTO QUE PODAMOS.</p>',
                    },
                ],
            },
            'cat2': {
                title: 'Categoria 2',
                description: 'large lorem ipsum dolor sit ammet',
                questions: [],
            },
            'cat3': {
                title: 'Categoria 3',
                description: 'large lorem ipsum dolor sit ammet',
                questions: [],
            },
        },
    },
    login_link: {
        hero: {
            title: 'Gracias por usar los servicios de <br> TRAVELESOFT.',
            p: 'Estamos buscando su información'
        }
    },
    metodologia: {
        hero: {
            title: 'Un plan para cada evento',
            p: 'Nuestras metodologías paso a paso, facilitan la organización de las actividades a realizarse.',
        },
        section: {
            title: 'Siempre sabrás que es lo que sigue.',
            p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, deleniti dignissimos dolorum, facere iste itaque laborum.',
            plan: 'Solicita el plan completo',
        },
        momentos: {
            top: 'Top moments',
            p: 'Te compartimos momentos extraordinarios de días inolvidables.',
            canal: 'Ir a canal',
        },
        pasos: {
            bloqueo: 'Bloqueo de fecha',
            pagina: 'Página web online',
            agenda: 'Agenda planners date',
            crono: 'Cronograma transporte',
            tu_boda: '¡Tu boda!',
        },
        locacion: {
            destino: 'En destino',
            local: 'Local',
        },
    },
    pagos_modal: {
        title: 'Métodos de pago',
        generar: 'Generar Abono',
        metodo_pago: 'Métodos de pago', //  ****
        abonar: '¿Cuanto desea abonar?',
        tarjeta_datos: 'Tarjeta de credito o debito',
        btn: 'Confirmar',
        comision: 'Cargo por servicio',
        pagar: 'Importe a <br> Pagar',
        // ****
        montoMinimo: 'El monto mínimo para reservar es de:',
        montoMinimo_title: 'Monto mínimo',
        // ****
        cargo: {
            title: 'Depositos a cuenta, cargando comprobante.',
            // ****
            titleHTML: 'Depositos a cuenta <br> cargando comprobante.',
            // ****
            descripcion: 'El primer abono confirmara su reservación y el sistema le enviara solicitudes de pago mensualmente. Usted deberá de depositar a la cuenta bancaria proporcionada, el monto solicitado. Cada deposito debe de ser reportado en el sistema. Para esto, solo tiene que seguir las instrucciones de la carga de voucher, o dar clic al abono en el submenu *Mis abonos*  en la consola de administración de su reserva. 70 días antes de la fecha del evento, usted debera de realizar el deposito del saldo de su reservacion. La acreditacion del deposito y la nota correspondiente, son generadas de forma automatica, pero su validez esta sujeta a la comprobación del ingreso, por parte de la institución bancaria.',
            name: 'Cargo',
        },
        suscripcion: {
            title: 'Tarjeta recurrente.',
            descripcion: 'El modelo de tarjeta recurrente, crea un plan de pagos automatizado, que cargara a su tarjeta de crédito, un importe fijo cada 30 dias. El ultimo cargo sera aplicado 70 días antes de la fecha del evento y sera por el total del saldo pendiente de pago de la reserva. El importe a pagar mensual no puede ser modificado, una vez iniciado este plan de pagos. Comuníquese con nuestro personal de call center, si tiene alguna duda sobre esta modalidad de pago. ',
            name: 'Suscripcion',
        },
        stripe: {
            title: 'Cargo a tarjeta de crédito/débito. México y extranjero.',
            descripcion: 'El sistema le permitirá realizar el pago del monto seleccionado, utilizando su saldo disponible o la tarjeta bancaria, de su cuenta de Stripe. Deberá de ingresar con su usuario y contrasena y aceptar el cargo. Este método de pago no requiere que reporte su abono, ya que el sistema lo reconocerá de forma automática. Conserve su ficha para cualquier aclaración. Si usted desea crear una cuenta en Stripe, de clic en el botón Abrir una cuenta y siga las instrucciones.',
            name: 'Stripe.',
        },
        openpay: {
            title: 'Cargo a tarjeta de crédito/débito. México.',
            descripcion: "El sistema realizara un único cargo, por el importe seleccionado, a su tarjeta de crédito o débito. El procesador de pagos para 'Tarjetas de crédito/débito. México' es Openpay, nuestra empresa no guarda la información de su tarjeta. Los parametros de seguridad de Openpay son estrictos. Si su tarjeta es declinada y cuenta con saldo disponible, es posible que su banco haya emitido una alerta de seguridad, comuníquese con su banco para informar su deseo de realizar este pago con cargo a su tarjeta.",
            name: 'Openpay.',
        },
        paypal: {
            title: 'Paypal.',
            descripcion: 'El sistema le permitirá realizar el pago del monto seleccionado, utilizando su saldo disponible o la tarjeta bancaria, de su cuenta de Paypal. Deberá de ingresar con su usuario y contrasena y aceptar el cargo. Este método de pago no requiere que reporte su abono, ya que el sistema lo reconocerá de forma automática. Conserve su ficha para cualquier aclaración. Si usted desea crear una cuenta en Paypal, de clic en el botón Abrir una cuenta y siga las instrucciones.',
            name: 'Paypal',
        },
        virtual: {
            title: 'Tarjetas de Crédito/Débito emitidas en México.',
            // ****
            titleHTML: 'Tarjetas de Crédito/Débito <br> emitidas en México.',
            // ****
            descripcion: 'El sistema realizara un único cargo, por el importe seleccionado, a su tarjeta de crédito o débito. El motor de pagos es operado por la empresa OpenPay, nuestra empresa no guarda la información de su tarjeta. Por su seguridad, este método de pago solo permite cargos con monto máximo a $29,000 MX. Si usted asi lo requiere, puede generar nuevos abonos, en su consola de administración, hasta cubrir con el monto que desea cubrir. Los parametros de seguridad de OpenPay son estrictos. Si su tarjeta es declinada y cuenta con saldo disponible, es posible que su banco haya emitido una alerta de seguridad, comuníquese con su banco para informar su deseo de realizar este pago con cargo a su tarjeta.',
            name: 'Virtual',
        },
        tienda: {
            title: 'Pago en autoservicio (Paynet).',
            descripcion: 'El sistema generara una ficha de pago, para que usted realice el abono del monto seleccionado, en el autoservicio de su elección. Deberá de presentar la ficha impresa y proporcionarla al cajero, para seguir las instrucciones. Por su seguridad, este método de pago solo permite cargos con monto máximo a $10,000 MX. Si usted así lo requiere, puede generar nuevos abonos, en su consola de administración, hasta cubrir con el monto que desea cubrir. Este método de pago no requiere que reporte su abono, ya que el sistema lo reconocerá de forma automática. Conserve su ficha para cualquier aclaración.',
            name: 'Tienda',
        },

        swift: {
            title: 'Swift Interbancaria.',
            descripcion: 'Este método de pago deberá de ser seleccionado, únicamente por asistentes con cuentas bancarias y tarjetas de crédito fuera de México.Usted deberá de seguir las instrucciones para realizar su abono y reportar el comprobante a través del sistema. Si usted cuenta con una cuenta de Paypal, le recomendamos usarlo como método de pago, debido a que es un método mas sencillo.',
            name: 'Swift',
        },
    },
    checkout: {
        titular: 'Titular',
        datos_titular: 'Titular de la reservacion',
        estado_cuenta: 'Estado de cuenta',
        abr_hab: 'Habitación ',
        total_reserva: 'Total reserva',
        habitacion: 'Habitación',
        fecha_entrada: 'Fecha de entrada',
        fecha_de_salida: 'Fecha de salida',
        ocupacion: 'Ocupacion',
        categoria: 'Categoria',
        plan: 'Plan',
        acompanantes: 'Acompañantes adultos',
        menores: 'Menores',
        impuestos_cargos: 'Impuestos y cargos',
        detalle_pago: {
            titulo: 'Detalle Impuestos y Cargos',
            costo_habitacion: 'Costo de habitación',
            iva: 'IVA',
            ish: 'Impuesto Hospedaje',
            cargo_servicio: 'Cargo por servicio',
            total: 'Total',
        }
    },
    sidebar: {
        transporte: 'Transporte',
        callcenter: 'Solicitar llamada del Call Center',
        ticket: 'Abrir ticket de soporte',
        faqs: 'Preguntas Frecuentes',
    },
    thanks_you: {
        heroe: {
            title: 'Gracias por considerarnos <br>como tu agencia coordinadora.',
            p: 'En breve nos pondremos en contacto <br>con un maravilloso plan <br>a la medida de tus necesidades.'
        }
    },
    ticket: {
        numero: 'Numero de habitaciones',
        habitacion: 'Habitación',
    },
    end_reservation: {
        title: 'Gracias por utilizar los servicios  <br> de TRAVELESOFT',
        p: 'En breve recibirá un correo electronico',
    },
    eventos: {
        // ****
        mensaje1: 'Estadía mínima de ',
        mensaje2: 'noches por habitación.',
        mensaje3: 'Si usted reserva por otro medio deberá pagar ',
        mensaje4: 'USD por persona por nivelación tarifaria para acceder al evento.',
        mensaje5: 'Para mayor información, favor de contactarnos directamente.',
        esperamos: '¡Los esperamos!',
        galeria: 'Galeria',
        info: 'Información del evento',
        cita: 'La cita es en:',
        info_boda: 'Información del Evento',
        conteo: 'Countdown',
        // ****
        evento: {
            title: 'Encuentra el evento <br>al que fuiste invitado',
            demo: 'Evento demo',
            p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium cum cupiditate deleniti dolor earum est expedita.',
            btn: 'Confirmar Asistencia',
            info_boda: {
                title: 'Información del Evento',
                p: 'Estas son las actividades que hemos preparado para ti',
                ceremonia: {
                    title: 'Ceremonia',
                    p: 'Revisa los detalles de la ceremonia que llevaremos a cabo.',
                },
                cronograma: {
                    title: 'Cronograma',
                    p: 'Conoce el plan de actividades que estaremos realizando.',
                },
                felices: {
                    title: 'Historias felices',
                    // ----
                    p: 'Te invitamos a conocer las mejores historias de las parejas que disfrutaron al máximo, sin preocuparse por nada. Juntos haremos tu sueño realidad.',
                    // ----
                    btn: 'Ver historias felices'
                }
            }
        },
        ceremonia: {
            btn: 'Confirmar asistencia',
            section: {
                p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias autem blanditiis, dicta eveniet ex incidunt iste itaque nisi nulla obcaecati odit perspiciatis quasi qui recusandae similique tempore ut? Expedita!',
            }
        },
        cronograma: {
            p: 'Con mucho cariño hemos preparado este programa de actividades, para que nuestro evento esté lleno de fantásticos momentos de amor, convivencia y diversión. Esperamos sean de tu agrado y puedas acompañarnos. ¡Tu presencia y participación son lo más importante!',
            btn: 'Confirmar asistencia',
        },
        index: {
            btn: 'Confirmar asistencia',
            mesa_regalos: {
                title: 'Mesa de regalos',
                p: 'Tu asistencia es lo más importante para nosotros, <br> pero si quieres regalarnos algún detallito puedes hacerlo desde aquí',
                btn: 'Ver',
                mensaje: 'Este evento no cuenta con mesa de regalos',
            },
            info_boda: {
                title: 'Información de la boda',
                p: 'Estas son las actividades que hemos preparado para ti',
                ceremonia: {
                    title: 'Ceremonia',
                    p: 'Revisa los detalles de la ceremonia que llevaremos a cabo.',
                },
                cronograma: {
                    title: 'Cronograma',
                    p: 'Conoce el plan de actividades que estaremos realizando.',
                    btn_ver: 'Ver más'
                },
                onlive: {
                    title: 'Sigue la transmisión en vivo',
                    p: 'No te pierdas el evento y sigue nuestra transmisión en <br>facebook completamente en vivo.',
                    btn: 'Seguir la transmision',
                }
            }
        },
        invitados: {
            title: 'Invitados',
            p: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci alias autem blanditiis, dicta eveniet ex incidunt iste itaque nisi nulla obcaecati odit perspiciatis quasi qui recusandae similique tempore ut? Expedita!',
            btn: 'Confirmar asistencia',
        },
        proceso: {
            btn_regresar: 'REGRESAR',
            btn_continuar: 'CONTINUAR',
            pasos: {
                contacto: 'Datos de contacto',
                pase: 'Pase',
                checkout: 'Checkout',
            }
        }
    },
    aviso_contacto: {
        aceptado: {
            title: '¡Gracias!',
            p: 'En breve nos comunicaremos contigo, <br> mientras tanto...',
            btn_visita: 'Visita la página <br>de los novios',
            btn_consulta: 'Consulta las preguntas <br>frecuentes',
        },
        index: {
            title: '¡Queremos contactarte!',
            p: 'En relación a tu asistencia al evento de Andrea y Juan',
            block: 'Te agradecemos unos minutos de tu tiempo.<br> Nuestro equipo de soporte, esta listo para realizarte una llamada, en los próximos minutos.',
            btn_tomar_llamada: 'Tomar llamada',
            btn_reagendar: 'Reagendar llamada',
            btn_no_asistira: 'No asistiré',
        },
        rechazado: {
            title: '¡Gracias!',
            p: 'Sentimos saber que no podrás acompañarnos.<br> Te extrañaremos mucho, en este día tan especial.<br> Si el panorama cambia, estaremos encantados de poder ayudarte.',
        }
    },
    admin_boda: {
        // ****
        invitados: {
            title: 'INVITADOS',
            subtitle: 'Nuevos(s) invitado(s)',
            btn: 'Agregar Invitado',
            listado: 'Listado de Invitados',
            historial: 'Historial',
            llamadas: 'Llamadas',
            sms: 'SMS',
            email: 'Email',
            acciones: 'Acciones',
            editar: 'Editar',
            contacto_ahora: 'Contactar Ahora',
            renvio_invitacion: 'Reenviar Invitacion',
            reenvio_sms: 'Reenviar SMS',
            reservar: 'Reservar',
            asiste: 'Asiste',
            no_asiste: 'No Asiste',
            borrar_invitado: 'Eliminar'
        },
        // ****
        // ****
        cuenta_maestra: {
            title: 'Cuenta Maestra',
            mi_saldo: 'Mi saldo',
            agregar_abono: 'Agregar un abono',
            motivo: 'Motivo del abono',
            cantidad_depositada: 'Cantidad Depositada',
            subir_voucher: 'Subir voucher',
            paquete: 'Paquete',
            servicio: 'Servicios contratados',
            costo: 'Costo',
            total_servicio: 'Total Servicios',
            abono: 'Abonos',
            abonos: 'Abonos',
            fecha: 'fecha',
            monto: 'Monto',
            total_abono: 'Total Abono',
        },
        notificaciones: {
            title: 'Notificaciones',
            without_notifications: 'No tienes notificaciones pendientes',
            approve_reservation: {
                title: 'Aprovar reservación',
                reservation_general_info: 'General',
                reservation_payments: 'Pagos',
                reservation_transport: 'Transporte',
                reservation_balance_account: 'Cuenta',
                reservation_key: 'Clave',
                reservation_titular: 'Titular',
                reservation_email: 'Correo',
                reservation_cellphone: '# Tel.',
                reservation_total: 'Total',
                reservation_total_paid: 'Abonado',
                reservation_limit_date_to_paid: 'Fecha límite de pago',
                reservation_room: 'Habitación {roomNumber}',
                reservation_room_approve: 'Aprobar Habitación {roomNumber}',
                reservation_room_category: 'Categoría: {category}',
                reservation_room_date_in: 'F. Entrada',
                reservation_room_date_out: 'F. Salida',
                reservation_room_meal_plan: 'Plan',
                reservation_room_occupation: 'Ocupación',
                reservation_room_minors: 'Menores',
                reservation_room_titular: 'Titular',
                reservation_room_minors_description: '{total} Menore(s)',
                reservation_room_adults_description: '{total} Adulto(s)',
                reservation_payment_title: 'Pago',
                reservation_payment_amount: 'Cantidad',
                reservation_payment_date: 'Fecha',
                reservation_payment_approve: 'Aprobar Pago',
                reservation_transport_type: 'Tipo',
                reservation_transport_occupation: 'Ocupación',
                reservation_transport_from_to: 'Origen/Destino',
                reservation_transport_date: 'Fecha',
                error_getting_information_title: 'Error al obtener Información!',
                error_getting_information_description: 'Puede que la información que estas consultando es antigua, por ejemplo una reservación o un pago eliminado.',
                error_getting_information_help: 'Si piensas que la información es válida, intenta nuevamente o contactanos para ayudarte.'
            },
        },
        asistentes: {
            title: 'Asistentes',
            nueva_reserva: 'Nueva Reserva',
            reservar_ahora: 'Reservar ahora',
            listado: 'Listado de reservas',
            detalles: 'Ver Detalles',
            estado_cuenta: 'Estado de Cuenta',
            historial: 'Historial',
            llamadas: 'Llamadas',
            sms: 'SMS',
            email: 'Email',
            acciones: 'Acciones',
            editar_titular: 'Editar Titular',
            agregar_hab: 'Agregar Habitacion',
            reenvio_email: 'Reenvio Email Reserva Exitosa',
            reenvio_sms: 'Reenvio SMS Reserva Exitosa',
            generar_abono: 'Generar Nuevo Abono',
            cancelacion: 'Solicitar Cancelacion',
            contacto_ahora: 'Contactar Ahora',
            delete: 'Eliminar Reservación',
            room_list: 'Room List',
            room_list_download: 'Descargar Excel',
        },
        navbar: {
            title: 'Admin Evento',
            sub_title: 'administre su evento desde este panel',
            invitados: 'Invitados',
            asistentes: 'Asistentes',
            cuenta: 'Cuenta maestra',
            notificaiones: 'Notificaciones',
            cronograma: 'Cronograma',
            callcenter: 'Call Center',
            soporte: 'Soporte',
            salir: 'Cerrar Sesion',
            config: 'Configuracion',
        },
        perfil: {
            title: 'Perfil',
            sub_title: 'administre la informacion de su evento',
            nombre: 'Nombre',
            telefono: 'Telefono',
            cumpleano: 'Cumpleaños',
            fecha_boda: 'Fecha del evento',
            minimo_noches: 'Mínimo de noches',
            email_notificacion: 'Correo para notificaciones',
            wedding_planner: 'Wedding Planner',
            wedding_pass: 'Precio wedding pass',
            archivo_adjunto: 'Informacion',
            monto_minimo: 'Monto mínimo para reservar',
            sms: 'Servicio SMS',
            email: 'Servicio email',
            pagos: 'Ser. pagos',
            sms_link: 'Información SMS',
            mesa_regalos: 'Mesa de regalos',
            transmision: 'Transmisión en vivo',
            galeria: 'Galeria',
            edit: 'Editables',
            guardar: 'Guardar',
            cambio_password: 'Cambiar contraseña',
            mensaje: 'Mensaje Personalizado',
            input_pass: 'Nueva contraseña',
            input_pass_re: 'Confirmar contraseña',
            mensaje_invi: 'Mensaje especial',
            textarea: 'Ingrese un mensaje para darle la bienvenida a sus invitados en su sitio personalizado',
            perfil: 'Perfil', //cirular
            portada: 'Portada', //rectangulo
            principal1: 'Principal 1',
            pricnipal2: 'Principal 2',
            pricnipal3: 'Principal 3',
            upload_img: 'Buscar imagenes'

        },
        soporte: {
            title: 'Soporte',
            title_mensaje: 'Contactar Agencia',
            title_call: 'Agendar una llamada',
            guardar: 'Guardar',
            horario: 'Seleccione un horario',
            mensaje: 'Ingrese su mensaje'
        },
        // ****
    },
    admin_master: {
        navbar: {
            head: {
                evento: 'Generales Evento',
                cronograma: 'Cronograma',
                invitado: 'Invitados',
                asistente: 'Asistentes',
                cuenta_maestra: 'Cuenta Maestra',
                notificacion: 'Notificaciones',
            },
            side: {
                dashboard: 'Dashboard',
                evento_lista: 'Mis Eventos',
                notificacion: 'Notificaciones',
                transporte: 'Transporte',
                cuenta_maestra: 'Cuenta Maestra',
                reporte: 'Reportes',
                perfil: 'Perfil',
                soporte: 'Soporte',
                call_center: 'Call Center'
            },
        },
        generales_evento: {
            titulo: 'Generales Evento',
            categoria_generales: 'Datos Generales',
            categoria_media: 'Media',
            perfil: 'Perfil',
            background: 'Background',
            galeria: 'Galería de Evento',
            video: 'Vídeo',
            categoria_mensaje: 'Mensaje General',
            mensaje: 'Mensaje Personalizado',
        },
        cronograma: {
            titulo: 'Cronograma',
            categoria_mensaje: 'Mensaje Cronograma',
            mensaje: 'Mensaje Personalizado',
            crear_actividad: 'Crear Actividad',
            galeria: 'Galería vacía'
        },
        crear_actividad: {
            crear_locacion: {
                crear: 'Crear',
                titulo: 'Agregar locación a sede',
                placeholder: 'Ingresa el nombre de la locación'
            },
            titulo: {
                titulo: 'Cronograma',
                sub_title: 'Crear Nuevo',
            },
            categoria_actividad: 'Nueva Actividad',
            nombre: 'Nombre de Actividad',
            fecha: 'Fecha de Actividad',
            hora: {
                hora_inicial: 'Hora Inicial',
                hora_final: 'Hora Final',
            },
            sede: 'Sede',
            locacion: 'Locación',
            costo: {
                costo: 'Con Costo',
                gratuito: 'Gratuito',
            },
            edad: 'Edad Mínima',
            recomendacion: 'Recomendaciones',
            descripcion: 'Descripción',
            codigo_vestimenta: 'Código de Vestimenta',
            vestimenta: {
                hombre: 'Caballeros',
                mujer: 'Damas',
            },
            observacion: 'Observaciones',
            portada: 'Foto de Portada',
            video: 'Link del Vídeo',
        },
        notificaciones: {
            tabla: {
                titulo: 'Listado de Notificaciones',
                tipo: 'Tipo',
                notificacion: 'Notificación',
                fecha: 'Fecha',
                estatus: 'Estatus',
                detalle: 'Detalles',
            }
        },
        transporte: {
            titulo: 'Cotización Transporte',
            tabla: {
                titulo: 'Listado de Transporte',
                evento: 'Nombre Evento',
                pasajero: 'Nombre Pasajero',
                correo: 'Correo',
                celular: 'Celular',
                transporte: 'Transporte',
                accion: {
                    titulo: 'Acciones',
                    eliminar: 'Eliminar',
                }
            }
        },
        cuenta_maestra: {
            servicio_form: {
                titulo: 'Habitaciones y Servicios Contratados',
                concepto: 'Concepto',
            },
            servicio_tabla: {
                titulo: 'Servicios Contratados',
                concepto: 'Concepto',
                precio: 'Precio',
            },
            abono_form: {
                titulo: 'Abonos Realizados',
                concepto: 'Concepto',
            },
            abono_tabla: {
                titulo: 'Abonos Paquetes',
                concepto: 'Concepto',
                precio: 'Precio',
                accion: {
                    titulo: 'Acciones',
                    enviar: 'Enviar Nota',
                    imprimir: 'Imprimir Nota',
                    eliminar: 'Eliminar Nota',
                }
            },
        },
        perfil: {
            titulo: 'Configuración Agencia',
            categoria_general: 'Datos Generales',
            logo: 'Logotipo',
            subir_logo: 'Subir Logotipo',
            cambio_contrasena: 'Cambiar Contraseña',
            confirma_contrasena: 'Confirmar Contraseña',
            nombre: 'Nombre Agencia',
            correo_contacto: 'Correo Contacto',
            correo_soporte: 'Correo Soporte',
            correo_no: 'Correo NoReply',
            url: 'URL Agencia',
            horario: 'Horarios',
            direccion: 'Dirección',
            demo: 'Boda Demo',
            telefono_1: 'Teléfono 1',
            telefono_2: 'Teléfono 2',
            telefono_3: 'Teléfono 3',
            telefono_4: 'Teléfono 4',
            categoria_sobre: 'Sobre la Agencia',
            descripcion: 'Descripción',
            categoria_redes: 'Redes Sociales',
        },
        btn: {
            guardar: 'Guardar',
            regresar: 'Regresar',
        },
        centro_llamadas: {
            titulo: 'Centro de llamadas',
            tabla: {
                titulo: 'Llamadas pendientes'
            },
            modal_detalles: {
                solicitud: 'Solicitud',
                datos_contacto: 'Datos de contacto',
                historial: 'Historial',
                evento: 'Evento',
                llamada_realizada: '¿Llamada realizada?',
                llamada_titulo: 'Llamada',
                reprogramar_titulo: 'Reprogramar llamada',
                llamada: {
                    estados: {
                        por_hacer: 'Por realizar',
                        no_contestada: 'No ha respondido',
                        rechazada: 'Rechazada',
                        numero_incorrecto: 'Número equivocado',
                        completada: 'Completada',
                    },
                    prioridad: {
                        baja: 'baja',
                        media: 'media',
                        alta: 'alta',
                    }
                }
            }
        }
    },
    form_reservacion: {
        sub_total: 'Sub total habitación',
        calcular: 'Calcular Costo',
        btn: 'Enviar',
        menores: {
            no: 'Sin menores',
            uno: '1 menor',
            dos: '2 menores',
            tres: '3 menores'
        },
        acompanante: 'Acompañante',
        impuestos_cargos: '+ Impuestos y cargos',
        conversion: 'Las conversión mostrada es un valor aproximado',
    },
    // ****
    admin_reserva: {
        title: 'ADMINISTRA TU RESERVACIÓN',
        clave: 'Clave de reservación',
        nuevo_abono: 'Nuevo Abono ',
        nueva_hab: 'Nueva Habitación',
        mis_abonos: 'Mis Abonos',
        cronograma: 'Cronograma',
        datos_bancarios: 'Datos bancarios',
        titular: 'Titular',
        estado_cuenta: 'Estado de cuenta',
        habitacion: 'Habitación',
        total: 'Total reserva',
        abonado: 'Total abonado',
        saldo: 'Saldo',
        limite: 'Fecha limite de pago',
        detalles: 'Detalles',
        evento: 'Evento',
        entrada: 'Fecha de entrada',
        salida: 'Fecha de salida',
        ocupacion: 'Ocupación',
        menores: 'Menores',
        plan: 'Plan',
        agregar_hab: 'Agregar Habitación',
        edit_hab: 'Editar Habitación',
        regresar: 'Regresar',
        salir: 'Salir',
    },
// ****
    cronologia_card: {
        title: 'Cronograma de actividades', // ****
        actividad: 'Actividad',
        costo: 'Costo',
        codigo_vestimenta: 'Código de vestimenta',
        hombres: 'Hombres',
        mujeres: 'Mujeres',
        edad: 'Edad mínima',
        tipo: 'Tipo de actividad',
        ver_galeria: 'Fotos',
        ver_video: 'Ver video',
        ubicacion: 'Ubicación',
        descripcion: 'Descripción',
        recomendacion: 'Recomendaciones',
        observaciones: 'Observaciones',
        sede: 'SEDE',
    },
    cuenta_regresiva: {
        dias: 'dias',
        horas: 'horas',
        minutos: 'minutos',
        segundos: 'segundos',
    },
    datepicker: {
        title: 'Selecciona las fechas de tu evento',
        flexible: 'Flexible',
        btn_agregar: 'Agregar',
        del: 'Del',
        al: 'Al',
        aplicar: 'Aplicar',
        estaciones: {
            primavera: 'Primavera',
            verano: 'Verano',
            otono: 'Otoño',
            invierno: 'Invierno',
        },
        meses: {
            enero: 'Enero',
            febrero: 'Febrero',
            marzp: 'Marzo',
            abril: 'Abril',
            mayo: 'Mayo',
            junio: 'Junio',
            julio: 'Julio',
            agosto: 'Agosto',
            septiembre: 'Septiembre',
            octubre: 'Octubre',
            noviembre: 'Noviembre',
            diciembre: 'Diciembre',
        }
    },
    buscador: {
        texto: 'Ingrese clave del Evento'
    },
    hotel_card: {
        precio: 'Precio por persona/por noche ',
        seleccionar: 'Seleccionar',
    },
    titular_reserva: {
        title: 'Actualizar Titular',
        numero_hab: 'Numero de habitaciones', // ****
    },
    habitacion: {
        list: 'Habitaciones',
        detalle: 'Detalles',
        habitacion: 'Habitación',
        titular: 'Titular',
        datos_titular: 'Datos del titular',
        estado_cuenta: 'Estado de cuenta',
        abr_hab: 'Hab. ',
        total_reserva: 'Total reserva',
        fecha_entrada: 'Fecha de entrada',
        fecha_de_salida: 'Fecha de salida',
        ocupacion: 'Ocupación',
        categoria: 'Categoria',
        plan: 'Plan',
        acompanantes: 'Acompañantes',
        zero_rooms: 'No hay habitaciones'
    },
    agregar_invitado: {
        title: 'Invitado',
    },
    email_historial: {
        tipo: 'Tipo',
        evento: 'Evento',
        title: 'Historial Emails',
        no_email: 'Sin emails'
    },
    llamada_historial: {
        title: 'Historial Llamdas',
        no_llamada: 'Sin llamada',
        inicio: 'Hora Inicio',
        fin: 'Hora Fin',
        tiempo: 'Tiempo(s)',
        observacion: 'Observación',
        estado: 'Estado',
        grabacion: 'Grabacion',
        reproducir: 'Reproducir',
    },
    // ****
    sms_historial: {
        title: 'Historial SMS',
        no_llamada: 'Sin SMS',
        fecha: 'Fecha',
        tipo: 'Tipo',
        estado: 'Estado',
        mensaje: 'mensaje',
    },
    solicitar_llamada: {
        title: 'Solicitar Llamada',
    },
    // ****
    mis_abonos: {
        mis_abonos: 'Mis abonos',
        total: 'Total reserva',
        pagado: 'Total pagado',
        monto: 'Monto',
        tipo: 'Tipo',
        fecha: 'Fecha',
        hora: 'Hora',
        estado: 'Estado',
        enviar_nota: 'Enviar Nota',
        reenviar_comprobante: 'Reenviar comprobante de pago',
        reenviar_ficha: 'Reenviar ficha de pago',
        enviar_ficha: 'Enviar Ficha',
        send_instructions: 'Enviar Instrucciones',
        voucher: 'Subir Voucher',
        nuevo_abono: 'Nuevo Abono',
        cantidad_deposito: 'Cantidad depositada',
        fecha_deposito: 'Fecha del deposito',
        zero_movements: 'No hay movimientos'
    },
    modal_transporte: {
        title: 'Transporte',
        aereo: 'Aereo',
        terrestre: 'Terrestre',
        nombre: 'Nombre del titular',
        num_adultos: 'Num. adultos',
        num_menores: 'Num. menores',
        ida: 'Fecha de ida',
        vuelta: 'Fecha de vuelta',
        origen: 'Origen',
        destino: 'Destino',
        btn: 'Solicitar',
    },
    instrucciones: {
        title: 'Notificar Abono',
        subtitle: 'Intrucciones para subir un voucher',
        p: 'Favor de depositar o transferir a la siguiente cuenta bancaria el importe de su abono. Después, siga los pasos enlistados.',
        mexico: {
            title: 'PARA TRANSFERENCIAS DESDE MÉXICO',
            razon: 'Razón Social',
            banco: 'Banco',
            sucursal: 'Sucursal',
            tarjeta: 'Número de Tarjeta',
            cuenta: 'Cuenta',
            clabe: 'Clabe',
        },
        usa: {
            title: 'PARA TRANSFERENCIAS DESDE EE.UU FOR TRANSFERS FROM U.S.',
            account: 'Account Holder',
            address: 'Address',
            phone: 'Phone Number',
            aba: 'ABA Citibank',
            swift: 'SWIFT Citibank',
            email: 'Email',
            clabe: 'Clabe Account',
        },
        pasos: {
            paso1: 'Da click en "Mis Abonos".',
            paso2: 'Seleccione la orden de pago que corresponda al importe y contenga un botón azul. Esto abrirá una nueva ventana.',
            paso3: 'Seleccione la imagen o utilice su teléfono para tomar y subir la imagen.',
            paso4: 'Presione el botón "Enviar Voucher"',
        },
        duda: 'Cualquier duda, comuníquese con nosotros y con gusto le atenderemos.',
    },
    // ****
    footer: {
        telefono: 'Teléfonos',
        horario: 'Horario de atención',
        direccion: 'Dirección',
        correos: 'Correos electrónicos',
        privacidad: 'Aviso de privacidad',
        terminos: 'Terminos y condiciones',
        copy: 'Copyright 2022 TraveleSoft',
        es: 'Español',
        en: 'English',
        horario_default: 'Lunes a Viernes desde 9:00 hasta 17:00 hrs',
    },
    error_http: {
        404: 'Página no encontrada'
    },
    ceremonia: {
        titulo: 'Ceremonia',
        locacion: 'Locación',
        party: 'Wedding Party',
        conteo: 'Countdown',
    },
    cronograma: {
        titulo: 'Cronograma',
        conteo: 'Countdown',
    },
    invitados: {
        mesa1: 'Mesa 1',
        mesa2: 'Mesa 2',
        mesa3: 'Mesa 3',
        mesa4: 'Mesa 4',
        mesa5: 'Mesa 5',
        mesa6: 'Mesa 6',
        conteo: 'Countdown',
    },
    snotify: {
        title: {
            registro_ing: 'Registro ingresado',
            solicitud_enviada: 'Solicitud enviada',
            exito: 'Operación realizada',
            eliminado: 'Registro eliminado',
            error: 'Error inesperado',
            campo_vacio: 'Campo vacío',
        },
        mensaje: {
            reserva_exito: 'La reserva fue creada con exito ',
            soporte_enviado: 'Ticket de soporte enviado a su wedding planner',
            equipo: 'Nuestro equipo lo contactará en breve ',
            registro_actu: 'Registro actualizado',
            registro_elim: 'Registro eliminado',
            registro_nuevo: 'Registro ingresado',
            voucher_subir: 'El abono fue creado, una organizador validará el pago',
            ficha_creada: 'Ficha de pago creada y enviada',
            correo_enviado: 'Email enviado, revise su bandeja de entrada',
            sms_enviado: 'SMS enviado, en breve recibirá la notificaion en su smartphone',
            cancelar: 'Una Organizador atenderá su solicitud',
            llamada: 'Su llamada fue programada, nuestro equipo de soporte lo atenderá',
            asistencia_mod: 'Asistencia modificada',
            error: 'Algo salio mal intentelo de nuevo',
            success: 'Operación realizada con éxito.',
            mo_imagen: 'Seleccione una imagen',
            campo_vacio: 'No se permiten campos vacíos',
            mensaje_wedding: 'Se envió el mensaje a su agencia, lo atenderemos a la brevedad posible',
        },
    },
    // ****
    pages: {
        home: 'Inicio',
        contacts: 'Contactos'
    },
    form: {
        nombre: 'Nombre',
        correo: 'Correo Electronico',
        descargar: 'Descargar',
        nombre_completo: 'Nombre Completo',
        celular: 'Celular',
        temporada: '¿En qué mes o temporada quieres llevar a cabo tu boda?',
        mensaje: '¿Qué detalles debemos considerar, para encontrar el hotel perfecto para ti?',
        mensaje1: 'Escribe tu mensaje',
        clave: 'Clave confirmación o Password',
        ingrese_nombre: 'Ingrese su nombre de invitado',
        input_email: 'tucorreo@email.com',
        enter_name_extra_person: 'Ingrese nombre de su acompañante',
        nombre_no_aparece: 'Mi nombre no aparece en el listado (ingreso manual)',
        categoria: 'Categoria',
        ocupacion: 'Ocupación',
        acompanantes: 'Acompañantes',
        num_menores: 'Número de menores',
        edad: 'Edad',
        enviar: 'Enviar',
        confirmar: 'Confirmar',
        observaciones: 'Observaciones',
        btn_guardar: 'Guardar',
        nombre_menor: 'nombre_Menor',
        ayudarte: '¿En qué podemos ayudarte?',
        fechaSelect: 'Seleccione una fecha',
        cancelar: 'Cancelar',
        view: 'Ver',
        update: 'Actualizar',
        mark_as_read: 'Marcar como leído',
        rol: 'Rol',
        // ****
    },
    status: {
        on: 'Activ@',
        off: 'Apagad@',
        canceled: 'Cancelado',
        on_hold: 'En Espera'
    },
    error: {
        key_not_found: "Clave no encontrada",
    },
    roles: {
        invitado: 'Invitado',
        asistente: 'Asistente',
        organizador: 'Organizador',
    },
    callcenter: {
        tipo_contacto_modal: {
            titulo: 'Tipo de contacto',
            llamada: 'Llamada',
            correo: 'Correo',
            whatsapp: 'WhatsApp',
            descripcion_comentarios: 'Comentarios acerca de la llamada',
            siguiente_llamada: 'Siguiente llamada',
            acciones: {
                defecto: 'Acciones',
                reservar: 'Reservar',
                nueva_habitacion: 'Nueva habitación',
                abonar: 'Abonar',
                reenviar_invitacion: 'Reenviar invitación',
                reenviar_bienvenida: 'Reenviar bienvenida',
                reenviar_cupon: 'Reenviar cupón',
                cancelacion: 'Solicitar cancelacion',
                no_asiste: 'No asiste',
                reprogramar: 'Otra',
            }
        },
        crear_llamada_modal: {
            historial: 'Historial',
            podemos_ayudarte: '¿En qué podemos ayudarte?',
            fecha: 'Fecha',
            boton_solicitar: 'Solicitar',
        },
        historial_llamada_modal: {
            solicitud: 'Solicitud',
            accion: 'Acción',
            comentarios: 'Comentarios',
            medio: 'Medio',
            fecha_programada: 'Fecha programada',
            fecha_realizada: 'Fecha realizada',
            tipo: 'Tipo',
            estado: 'Estado',
            espera: 'En espera',
            finalizada: 'Finalizada',
        },
        detalles_user: {
            detalles: 'Detalles del ',
            nombre: 'Nombre',
            correo: 'Correo electrónico',
            telefono: 'Teléfono',
            clave_reservacion: 'Clave reservación'
        },
        informacion_hacer_reservacion: {
            datos_reservar: 'Datos para reservar',
            categoria_principal: 'Categoria principal',
            estadia_minima: 'Estadia minima',
            inventario: 'Inventario',
            wedding_pass: 'Wedding pass',
            fecha_cierre: 'Fecha cierre',
        },
        detalles_reservacion: {
            mis_abonos: 'Mis abonos',
            estado_cuenta: 'Estado de cuenta',
            habitacion: 'Habitación ',
            categoria: 'Categoria',
            ocupacion: 'Ocupación',
            plan_alimentos: 'Plan alimentos',
            fecha_entrada: 'Fecha entrada',
            fecha_salida: 'Fecha salida',
            titular: 'Titular',
            acompanantes: 'Acompañantes',
        },
        acompanantes_modal: {
            titulo: 'Acompañantes',
            nombre: 'Nombre',
            edad: 'Edad',
            sin_adultos: 'Sin adultos',
            adulto: 'Adulto',
            adultos: 'Adultos',
            menor: 'Menor',
            menores: 'Menores',
            sin_menores: 'Sin menores',
            anio: ' año(s)'
        },
        historial_llamadas: 'Llamadas',
        al_dia: '¡Felicidades!<br/>Estás al día con las llamadas',
        proximas_llamadas: 'Ver próximas llamadas',
    },
    panel_control: {
        roles: {
            admin: 'Administrador',
            agency: 'Agencia',
            agencyUser: 'Usuario De Agencia',
            attendee: 'Asistente',
            organizer: 'Organizadores (novios)',
            payment: 'Pago',
            weddingPlanner: 'Wedding Planner',
            wholesaler: 'Mayorista',
        }
    },
}
