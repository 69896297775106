import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21b503e4 = () => interopDefault(import('../pages/adminReservacion.vue' /* webpackChunkName: "pages/adminReservacion" */))
const _cc9f9ff6 = () => interopDefault(import('../pages/aviso-contacto/index.vue' /* webpackChunkName: "pages/aviso-contacto/index" */))
const _3b528d7a = () => interopDefault(import('../pages/BarProgress.vue' /* webpackChunkName: "pages/BarProgress" */))
const _8a734f08 = () => interopDefault(import('../pages/CheckOut.vue' /* webpackChunkName: "pages/CheckOut" */))
const _a67b807c = () => interopDefault(import('../pages/ContactInformation.vue' /* webpackChunkName: "pages/ContactInformation" */))
const _14b8c09c = () => interopDefault(import('../pages/ContactInformation-old.vue' /* webpackChunkName: "pages/ContactInformation-old" */))
const _4391d305 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d10c528a = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _78d432d3 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _6b51faf8 = () => interopDefault(import('../pages/historias-felices.vue' /* webpackChunkName: "pages/historias-felices" */))
const _b265672e = () => interopDefault(import('../pages/Loader.vue' /* webpackChunkName: "pages/Loader" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0cddbf94 = () => interopDefault(import('../pages/login-link.vue' /* webpackChunkName: "pages/login-link" */))
const _23a2c41e = () => interopDefault(import('../pages/loginDoor.vue' /* webpackChunkName: "pages/loginDoor" */))
const _47044974 = () => interopDefault(import('../pages/luna-de-miel.vue' /* webpackChunkName: "pages/luna-de-miel" */))
const _f1c65110 = () => interopDefault(import('../pages/metodologia.vue' /* webpackChunkName: "pages/metodologia" */))
const _23c0c764 = () => interopDefault(import('../pages/pagoModal.vue' /* webpackChunkName: "pages/pagoModal" */))
const _04b6f7b0 = () => interopDefault(import('../pages/paquetes.vue' /* webpackChunkName: "pages/paquetes" */))
const _f02f73d0 = () => interopDefault(import('../pages/reservationOrder.vue' /* webpackChunkName: "pages/reservationOrder" */))
const _18c56039 = () => interopDefault(import('../pages/reservationProcess.vue' /* webpackChunkName: "pages/reservationProcess" */))
const _3d547278 = () => interopDefault(import('../pages/SidebarOptions.vue' /* webpackChunkName: "pages/SidebarOptions" */))
const _004cb264 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _739e9376 = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _11a862e2 = () => interopDefault(import('../pages/Ticket.vue' /* webpackChunkName: "pages/Ticket" */))
const _1e42543c = () => interopDefault(import('../pages/training-center.vue' /* webpackChunkName: "pages/training-center" */))
const _45128552 = () => interopDefault(import('../pages/admin/Asistentes.vue' /* webpackChunkName: "pages/admin/Asistentes" */))
const _e46966da = () => interopDefault(import('../pages/admin/CuentaMaestra.vue' /* webpackChunkName: "pages/admin/CuentaMaestra" */))
const _bdc18e06 = () => interopDefault(import('../pages/admin/Invitados.vue' /* webpackChunkName: "pages/admin/Invitados" */))
const _6fa71760 = () => interopDefault(import('../pages/admin/Notificaciones.vue' /* webpackChunkName: "pages/admin/Notificaciones" */))
const _25a2a9e2 = () => interopDefault(import('../pages/admin/Perfil.vue' /* webpackChunkName: "pages/admin/Perfil" */))
const _4345fe02 = () => interopDefault(import('../pages/admin/Soporte.vue' /* webpackChunkName: "pages/admin/Soporte" */))
const _0242bff0 = () => interopDefault(import('../pages/adminmaster/AgenciaAdministrar.vue' /* webpackChunkName: "pages/adminmaster/AgenciaAdministrar" */))
const _18b27375 = () => interopDefault(import('../pages/adminmaster/Asistentes.vue' /* webpackChunkName: "pages/adminmaster/Asistentes" */))
const _1a8ba87a = () => interopDefault(import('../pages/adminmaster/CentroLlamadas.vue' /* webpackChunkName: "pages/adminmaster/CentroLlamadas" */))
const _4525c9f8 = () => interopDefault(import('../pages/adminmaster/CrearEvento.vue' /* webpackChunkName: "pages/adminmaster/CrearEvento" */))
const _55ce769d = () => interopDefault(import('../pages/adminmaster/Cronograma.vue' /* webpackChunkName: "pages/adminmaster/Cronograma" */))
const _9fffd34e = () => interopDefault(import('../pages/adminmaster/CuentaBancaria.vue' /* webpackChunkName: "pages/adminmaster/CuentaBancaria" */))
const _d10d0c96 = () => interopDefault(import('../pages/adminmaster/CuentaMaestra.vue' /* webpackChunkName: "pages/adminmaster/CuentaMaestra" */))
const _17268d6c = () => interopDefault(import('../pages/adminmaster/CuentaMaestraAdmin.vue' /* webpackChunkName: "pages/adminmaster/CuentaMaestraAdmin" */))
const _81e06c6e = () => interopDefault(import('../pages/adminmaster/Evento.vue' /* webpackChunkName: "pages/adminmaster/Evento" */))
const _3ba7c33a = () => interopDefault(import('../pages/adminmaster/ExitoStripe.vue' /* webpackChunkName: "pages/adminmaster/ExitoStripe" */))
const _16a5471f = () => interopDefault(import('../pages/adminmaster/Invitados.vue' /* webpackChunkName: "pages/adminmaster/Invitados" */))
const _c882e304 = () => interopDefault(import('../pages/adminmaster/Notificaciones.vue' /* webpackChunkName: "pages/adminmaster/Notificaciones" */))
const _00fc6055 = () => interopDefault(import('../pages/adminmaster/NotificacionesLista.vue' /* webpackChunkName: "pages/adminmaster/NotificacionesLista" */))
const _6dae89a2 = () => interopDefault(import('../pages/adminmaster/PanelAdministracion.vue' /* webpackChunkName: "pages/adminmaster/PanelAdministracion" */))
const _1c0bc200 = () => interopDefault(import('../pages/adminmaster/Perfil.vue' /* webpackChunkName: "pages/adminmaster/Perfil" */))
const _4ab9c8b2 = () => interopDefault(import('../pages/adminmaster/Permisos.vue' /* webpackChunkName: "pages/adminmaster/Permisos" */))
const _3bcbe2a6 = () => interopDefault(import('../pages/adminmaster/Reporte.vue' /* webpackChunkName: "pages/adminmaster/Reporte" */))
const _770bb365 = () => interopDefault(import('../pages/adminmaster/RolesUsuario.vue' /* webpackChunkName: "pages/adminmaster/RolesUsuario" */))
const _49302d90 = () => interopDefault(import('../pages/adminmaster/Transporte.vue' /* webpackChunkName: "pages/adminmaster/Transporte" */))
const _15472cb9 = () => interopDefault(import('../pages/adminmaster/Usuarios.vue' /* webpackChunkName: "pages/adminmaster/Usuarios" */))
const _59338e22 = () => interopDefault(import('../pages/aviso-contacto/aceptado.vue' /* webpackChunkName: "pages/aviso-contacto/aceptado" */))
const _5d24682e = () => interopDefault(import('../pages/aviso-contacto/rechazado.vue' /* webpackChunkName: "pages/aviso-contacto/rechazado" */))
const _94c6f88a = () => interopDefault(import('../pages/account/reset/_key/index.vue' /* webpackChunkName: "pages/account/reset/_key/index" */))
const _7bc31c26 = () => interopDefault(import('../pages/eventos/_id/index.vue' /* webpackChunkName: "pages/eventos/_id/index" */))
const _0de63f4a = () => interopDefault(import('../pages/event/_id/end-reservation.vue' /* webpackChunkName: "pages/event/_id/end-reservation" */))
const _06e949fa = () => interopDefault(import('../pages/eventos/_id/ceremonia.vue' /* webpackChunkName: "pages/eventos/_id/ceremonia" */))
const _2c6717c5 = () => interopDefault(import('../pages/eventos/_id/cronograma.vue' /* webpackChunkName: "pages/eventos/_id/cronograma" */))
const _575fe0f7 = () => interopDefault(import('../pages/eventos/_id/invitados.vue' /* webpackChunkName: "pages/eventos/_id/invitados" */))
const _f117f442 = () => interopDefault(import('../pages/eventos/_id/reservationProcess.vue' /* webpackChunkName: "pages/eventos/_id/reservationProcess" */))
const _c4f9ee92 = () => interopDefault(import('../pages/eventos/_id/ReservationSteps.vue' /* webpackChunkName: "pages/eventos/_id/ReservationSteps" */))
const _2c3e0e08 = () => interopDefault(import('../pages/reservation/_id/end-reservation.vue' /* webpackChunkName: "pages/reservation/_id/end-reservation" */))
const _21657bb6 = () => interopDefault(import('../pages/eventos/_id/reservation/_id_reservation/menuReservation.vue' /* webpackChunkName: "pages/eventos/_id/reservation/_id_reservation/menuReservation" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adminReservacion",
    component: _21b503e4,
    name: "adminReservacion___es"
  }, {
    path: "/aviso-contacto",
    component: _cc9f9ff6,
    name: "aviso-contacto___es"
  }, {
    path: "/BarProgress",
    component: _3b528d7a,
    name: "BarProgress___es"
  }, {
    path: "/CheckOut",
    component: _8a734f08,
    name: "CheckOut___es"
  }, {
    path: "/ContactInformation",
    component: _a67b807c,
    name: "ContactInformation___es"
  }, {
    path: "/ContactInformation-old",
    component: _14b8c09c,
    name: "ContactInformation-old___es"
  }, {
    path: "/contacto",
    component: _4391d305,
    name: "contacto___es"
  }, {
    path: "/en",
    component: _3d6810e8,
    name: "index___en"
  }, {
    path: "/eventos",
    component: _d10c528a,
    name: "eventos___es"
  }, {
    path: "/faqs",
    component: _78d432d3,
    name: "faqs___es"
  }, {
    path: "/historias-felices",
    component: _6b51faf8,
    name: "historias-felices___es"
  }, {
    path: "/Loader",
    component: _b265672e,
    name: "Loader___es"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login___es"
  }, {
    path: "/login-link",
    component: _0cddbf94,
    name: "login-link___es"
  }, {
    path: "/loginDoor",
    component: _23a2c41e,
    name: "loginDoor___es"
  }, {
    path: "/luna-de-miel",
    component: _47044974,
    name: "luna-de-miel___es"
  }, {
    path: "/metodologia",
    component: _f1c65110,
    name: "metodologia___es"
  }, {
    path: "/pagoModal",
    component: _23c0c764,
    name: "pagoModal___es"
  }, {
    path: "/paquetes",
    component: _04b6f7b0,
    name: "paquetes___es"
  }, {
    path: "/reservationOrder",
    component: _f02f73d0,
    name: "reservationOrder___es"
  }, {
    path: "/reservationProcess",
    component: _18c56039,
    name: "reservationProcess___es"
  }, {
    path: "/SidebarOptions",
    component: _3d547278,
    name: "SidebarOptions___es"
  }, {
    path: "/signup",
    component: _004cb264,
    name: "signup___es"
  }, {
    path: "/thank-you",
    component: _739e9376,
    name: "thank-you___es"
  }, {
    path: "/Ticket",
    component: _11a862e2,
    name: "Ticket___es"
  }, {
    path: "/training-center",
    component: _1e42543c,
    name: "training-center___es"
  }, {
    path: "/admin/Asistentes",
    component: _45128552,
    name: "admin-Asistentes___es"
  }, {
    path: "/admin/CuentaMaestra",
    component: _e46966da,
    name: "admin-CuentaMaestra___es"
  }, {
    path: "/admin/Invitados",
    component: _bdc18e06,
    name: "admin-Invitados___es"
  }, {
    path: "/admin/Notificaciones",
    component: _6fa71760,
    name: "admin-Notificaciones___es"
  }, {
    path: "/admin/Perfil",
    component: _25a2a9e2,
    name: "admin-Perfil___es"
  }, {
    path: "/admin/Soporte",
    component: _4345fe02,
    name: "admin-Soporte___es"
  }, {
    path: "/adminmaster/AgenciaAdministrar",
    component: _0242bff0,
    name: "adminmaster-AgenciaAdministrar___es"
  }, {
    path: "/adminmaster/Asistentes",
    component: _18b27375,
    name: "adminmaster-Asistentes___es"
  }, {
    path: "/adminmaster/CentroLlamadas",
    component: _1a8ba87a,
    name: "adminmaster-CentroLlamadas___es"
  }, {
    path: "/adminmaster/CrearEvento",
    component: _4525c9f8,
    name: "adminmaster-CrearEvento___es"
  }, {
    path: "/adminmaster/Cronograma",
    component: _55ce769d,
    name: "adminmaster-Cronograma___es"
  }, {
    path: "/adminmaster/CuentaBancaria",
    component: _9fffd34e,
    name: "adminmaster-CuentaBancaria___es"
  }, {
    path: "/adminmaster/CuentaMaestra",
    component: _d10d0c96,
    name: "adminmaster-CuentaMaestra___es"
  }, {
    path: "/adminmaster/CuentaMaestraAdmin",
    component: _17268d6c,
    name: "adminmaster-CuentaMaestraAdmin___es"
  }, {
    path: "/adminmaster/Evento",
    component: _81e06c6e,
    name: "adminmaster-Evento___es"
  }, {
    path: "/adminmaster/ExitoStripe",
    component: _3ba7c33a,
    name: "adminmaster-ExitoStripe___es"
  }, {
    path: "/adminmaster/Invitados",
    component: _16a5471f,
    name: "adminmaster-Invitados___es"
  }, {
    path: "/adminmaster/Notificaciones",
    component: _c882e304,
    name: "adminmaster-Notificaciones___es"
  }, {
    path: "/adminmaster/NotificacionesLista",
    component: _00fc6055,
    name: "adminmaster-NotificacionesLista___es"
  }, {
    path: "/adminmaster/PanelAdministracion",
    component: _6dae89a2,
    name: "adminmaster-PanelAdministracion___es"
  }, {
    path: "/adminmaster/Perfil",
    component: _1c0bc200,
    name: "adminmaster-Perfil___es"
  }, {
    path: "/adminmaster/Permisos",
    component: _4ab9c8b2,
    name: "adminmaster-Permisos___es"
  }, {
    path: "/adminmaster/Reporte",
    component: _3bcbe2a6,
    name: "adminmaster-Reporte___es"
  }, {
    path: "/adminmaster/RolesUsuario",
    component: _770bb365,
    name: "adminmaster-RolesUsuario___es"
  }, {
    path: "/adminmaster/Transporte",
    component: _49302d90,
    name: "adminmaster-Transporte___es"
  }, {
    path: "/adminmaster/Usuarios",
    component: _15472cb9,
    name: "adminmaster-Usuarios___es"
  }, {
    path: "/aviso-contacto/aceptado",
    component: _59338e22,
    name: "aviso-contacto-aceptado___es"
  }, {
    path: "/aviso-contacto/rechazado",
    component: _5d24682e,
    name: "aviso-contacto-rechazado___es"
  }, {
    path: "/en/adminReservacion",
    component: _21b503e4,
    name: "adminReservacion___en"
  }, {
    path: "/en/aviso-contacto",
    component: _cc9f9ff6,
    name: "aviso-contacto___en"
  }, {
    path: "/en/BarProgress",
    component: _3b528d7a,
    name: "BarProgress___en"
  }, {
    path: "/en/CheckOut",
    component: _8a734f08,
    name: "CheckOut___en"
  }, {
    path: "/en/ContactInformation",
    component: _a67b807c,
    name: "ContactInformation___en"
  }, {
    path: "/en/ContactInformation-old",
    component: _14b8c09c,
    name: "ContactInformation-old___en"
  }, {
    path: "/en/contacto",
    component: _4391d305,
    name: "contacto___en"
  }, {
    path: "/en/eventos",
    component: _d10c528a,
    name: "eventos___en"
  }, {
    path: "/en/faqs",
    component: _78d432d3,
    name: "faqs___en"
  }, {
    path: "/en/historias-felices",
    component: _6b51faf8,
    name: "historias-felices___en"
  }, {
    path: "/en/Loader",
    component: _b265672e,
    name: "Loader___en"
  }, {
    path: "/en/login",
    component: _aa18caba,
    name: "login___en"
  }, {
    path: "/en/login-link",
    component: _0cddbf94,
    name: "login-link___en"
  }, {
    path: "/en/loginDoor",
    component: _23a2c41e,
    name: "loginDoor___en"
  }, {
    path: "/en/luna-de-miel",
    component: _47044974,
    name: "luna-de-miel___en"
  }, {
    path: "/en/metodologia",
    component: _f1c65110,
    name: "metodologia___en"
  }, {
    path: "/en/pagoModal",
    component: _23c0c764,
    name: "pagoModal___en"
  }, {
    path: "/en/paquetes",
    component: _04b6f7b0,
    name: "paquetes___en"
  }, {
    path: "/en/reservationOrder",
    component: _f02f73d0,
    name: "reservationOrder___en"
  }, {
    path: "/en/reservationProcess",
    component: _18c56039,
    name: "reservationProcess___en"
  }, {
    path: "/en/SidebarOptions",
    component: _3d547278,
    name: "SidebarOptions___en"
  }, {
    path: "/en/signup",
    component: _004cb264,
    name: "signup___en"
  }, {
    path: "/en/thank-you",
    component: _739e9376,
    name: "thank-you___en"
  }, {
    path: "/en/Ticket",
    component: _11a862e2,
    name: "Ticket___en"
  }, {
    path: "/en/training-center",
    component: _1e42543c,
    name: "training-center___en"
  }, {
    path: "/en/admin/Asistentes",
    component: _45128552,
    name: "admin-Asistentes___en"
  }, {
    path: "/en/admin/CuentaMaestra",
    component: _e46966da,
    name: "admin-CuentaMaestra___en"
  }, {
    path: "/en/admin/Invitados",
    component: _bdc18e06,
    name: "admin-Invitados___en"
  }, {
    path: "/en/admin/Notificaciones",
    component: _6fa71760,
    name: "admin-Notificaciones___en"
  }, {
    path: "/en/admin/Perfil",
    component: _25a2a9e2,
    name: "admin-Perfil___en"
  }, {
    path: "/en/admin/Soporte",
    component: _4345fe02,
    name: "admin-Soporte___en"
  }, {
    path: "/en/adminmaster/AgenciaAdministrar",
    component: _0242bff0,
    name: "adminmaster-AgenciaAdministrar___en"
  }, {
    path: "/en/adminmaster/Asistentes",
    component: _18b27375,
    name: "adminmaster-Asistentes___en"
  }, {
    path: "/en/adminmaster/CentroLlamadas",
    component: _1a8ba87a,
    name: "adminmaster-CentroLlamadas___en"
  }, {
    path: "/en/adminmaster/CrearEvento",
    component: _4525c9f8,
    name: "adminmaster-CrearEvento___en"
  }, {
    path: "/en/adminmaster/Cronograma",
    component: _55ce769d,
    name: "adminmaster-Cronograma___en"
  }, {
    path: "/en/adminmaster/CuentaBancaria",
    component: _9fffd34e,
    name: "adminmaster-CuentaBancaria___en"
  }, {
    path: "/en/adminmaster/CuentaMaestra",
    component: _d10d0c96,
    name: "adminmaster-CuentaMaestra___en"
  }, {
    path: "/en/adminmaster/CuentaMaestraAdmin",
    component: _17268d6c,
    name: "adminmaster-CuentaMaestraAdmin___en"
  }, {
    path: "/en/adminmaster/Evento",
    component: _81e06c6e,
    name: "adminmaster-Evento___en"
  }, {
    path: "/en/adminmaster/ExitoStripe",
    component: _3ba7c33a,
    name: "adminmaster-ExitoStripe___en"
  }, {
    path: "/en/adminmaster/Invitados",
    component: _16a5471f,
    name: "adminmaster-Invitados___en"
  }, {
    path: "/en/adminmaster/Notificaciones",
    component: _c882e304,
    name: "adminmaster-Notificaciones___en"
  }, {
    path: "/en/adminmaster/NotificacionesLista",
    component: _00fc6055,
    name: "adminmaster-NotificacionesLista___en"
  }, {
    path: "/en/adminmaster/PanelAdministracion",
    component: _6dae89a2,
    name: "adminmaster-PanelAdministracion___en"
  }, {
    path: "/en/adminmaster/Perfil",
    component: _1c0bc200,
    name: "adminmaster-Perfil___en"
  }, {
    path: "/en/adminmaster/Permisos",
    component: _4ab9c8b2,
    name: "adminmaster-Permisos___en"
  }, {
    path: "/en/adminmaster/Reporte",
    component: _3bcbe2a6,
    name: "adminmaster-Reporte___en"
  }, {
    path: "/en/adminmaster/RolesUsuario",
    component: _770bb365,
    name: "adminmaster-RolesUsuario___en"
  }, {
    path: "/en/adminmaster/Transporte",
    component: _49302d90,
    name: "adminmaster-Transporte___en"
  }, {
    path: "/en/adminmaster/Usuarios",
    component: _15472cb9,
    name: "adminmaster-Usuarios___en"
  }, {
    path: "/en/aviso-contacto/aceptado",
    component: _59338e22,
    name: "aviso-contacto-aceptado___en"
  }, {
    path: "/en/aviso-contacto/rechazado",
    component: _5d24682e,
    name: "aviso-contacto-rechazado___en"
  }, {
    path: "/en/account/reset/:key",
    component: _94c6f88a,
    name: "account-reset-key___en"
  }, {
    path: "/account/reset/:key",
    component: _94c6f88a,
    name: "account-reset-key___es"
  }, {
    path: "/en/eventos/:id",
    component: _7bc31c26,
    name: "eventos-id___en"
  }, {
    path: "/en/event/:id?/end-reservation",
    component: _0de63f4a,
    name: "event-id-end-reservation___en"
  }, {
    path: "/en/eventos/:id/ceremonia",
    component: _06e949fa,
    name: "eventos-id-ceremonia___en"
  }, {
    path: "/en/eventos/:id/cronograma",
    component: _2c6717c5,
    name: "eventos-id-cronograma___en"
  }, {
    path: "/en/eventos/:id/invitados",
    component: _575fe0f7,
    name: "eventos-id-invitados___en"
  }, {
    path: "/en/eventos/:id/reservationProcess",
    component: _f117f442,
    name: "eventos-id-reservationProcess___en"
  }, {
    path: "/en/eventos/:id/ReservationSteps",
    component: _c4f9ee92,
    name: "eventos-id-ReservationSteps___en"
  }, {
    path: "/en/reservation/:id?/end-reservation",
    component: _2c3e0e08,
    name: "reservation-id-end-reservation___en"
  }, {
    path: "/en/eventos/:id/reservation/:id_reservation?/menuReservation",
    component: _21657bb6,
    name: "eventos-id-reservation-id_reservation-menuReservation___en"
  }, {
    path: "/eventos/:id",
    component: _7bc31c26,
    name: "eventos-id___es"
  }, {
    path: "/event/:id?/end-reservation",
    component: _0de63f4a,
    name: "event-id-end-reservation___es"
  }, {
    path: "/eventos/:id/ceremonia",
    component: _06e949fa,
    name: "eventos-id-ceremonia___es"
  }, {
    path: "/eventos/:id/cronograma",
    component: _2c6717c5,
    name: "eventos-id-cronograma___es"
  }, {
    path: "/eventos/:id/invitados",
    component: _575fe0f7,
    name: "eventos-id-invitados___es"
  }, {
    path: "/eventos/:id/reservationProcess",
    component: _f117f442,
    name: "eventos-id-reservationProcess___es"
  }, {
    path: "/eventos/:id/ReservationSteps",
    component: _c4f9ee92,
    name: "eventos-id-ReservationSteps___es"
  }, {
    path: "/reservation/:id?/end-reservation",
    component: _2c3e0e08,
    name: "reservation-id-end-reservation___es"
  }, {
    path: "/eventos/:id/reservation/:id_reservation?/menuReservation",
    component: _21657bb6,
    name: "eventos-id-reservation-id_reservation-menuReservation___es"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
